<!-- need to add size dependencies and hint functionality -->
<template>
  <div class="input__wrapper">
    <label v-if="labelOutside" :for="inputId" class="label label_size_S">{{ label }}</label>
    <div class="input-field">
      <input
        :class="[
          'input',
          `input_size_${size}`,
          'input_type_default',
          { [`input_error_active`]: isErrorActive }
        ]"
        :type="internalType"
        v-model="internalValue"
        :placeholder="placeholder"
        :disabled="readOnly"
        :id="inputId"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        :autocomplete="autocomplete"
      />
      <label v-if="!labelOutside" :class="{ 'input-label': true, 'input-label_active': internalValue || isFocused }" :for="inputId">{{ label }}</label>
      <div class="input-icon" v-if="internalValue && !readOnly">
        <svg-icon 
          v-if="type === 'password'"
          class="icon" 
          :iconName="getPasswordIconProps.name" 
          :size="getPasswordIconProps.size" 
          @click="togglePasswordInput"
        />
        <template v-else>
          <svg-icon 
            v-if="size==='M' || size==='L'"
            class="icon icon_type_close" 
            :iconName="'kuiIconCloseLarge'" 
            :size="'24px'" 
            @click="clearInput"
          />
          <svg-icon 
            v-if="size!=='M' && size!=='L'"
            class="icon icon_type_close" 
            :iconName="'kuiIconClose'" 
            :size="'16px'" 
            @click="clearInput"
          />
        </template>
      </div>
    </div>
    <label v-if="description" :for="inputId" class="label label_size_S">{{ description }}</label>
    <label v-if="isErrorActive" :for="inputId" class="label label_size_S error">{{ errorText }}</label>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default{
  name: 'input-default',
  props: {
    size:{
      type: String,
      default: 'M',
    },
    value: {
      type: [String, Number],
      default: ''
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    label: String,
    placeholder: String,
    readOnly:{
      type: Boolean,
      default: false
    },
    autocomplete:{
      type: String,
      default: 'off'
    },
    maxlength: [String, Number],
    label:{
      type: String,
      default: 'Label is here'
    },
    description:{
      type: String,
      default: ''
    },
    labelOutside:{
      type: Boolean,
      default: false
    },
    type:{
      type: String,
      default: 'text'
    },
    isErrorActive:{
      type: Boolean,
      default: false,
    },
    errorText:{
      type: String,
      default: 'It seems you are under attack. Run!'
    }
  },
  data() {
    return {
      // This is required for hide/show password without impact on other actions  
      internalType: 'text',
      passwordVisibility: false,
      internalValue: this.value,
      isFocused: false,
      inputId: uuidv4(),
    };
  },
  watch: {
    value: function(newVal) {
      this.internalValue = newVal;
    },
    internalValue: function(newVal) {
      this.$emit('update:value', newVal);
    }
  },
  computed: {
    getPasswordIconProps() {
      const props = {};
      if (this.size === 'M' || this.size === 'L') {
        props.size = '24px';
        props.name = this.passwordVisibility ? 'kuiIconHideLarge' : 'kuiIconShowLarge';
      } else {
        props.size = '16px';
        props.name = this.passwordVisibility ? 'kuiIconEyeClosed' : 'kuiIconEyeOpen';
      }
      return props;
    }
  },
  methods:{
    clearInput() {
      if (!this.readOnly) {
        this.internalValue = '';
        this.isFocused = false;
      }
    },
    onInput(event) {
      this.internalValue = event.target.value;
      this.$emit('update:value', this.internalValue);
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      if (!this.internalValue) {
        this.isFocused = false;
      }
    },
    togglePasswordInput() {
      this.passwordVisibility = !this.passwordVisibility;
      this.internalType = this.passwordVisibility ? 'text' : 'password';
    }
  },
  created() {
    this.internalType = this.type;
  }
}
</script>

<style scoped>
@import '../inputs.css';

.input__wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input-field {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-label {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  transition: 0.3s ease all;
}

.input:disabled + .input-label {
  color: var(--kaz-textIcons-text-03);
}

.input-label_active {
  top: 6px;
  transform: translateY(0%);
  font-size: 0.75em;
  color: var(--kaz-textIcons-text-02);
}

.input {
  position: relative;
  width: 100%;
}

.input_type_default:not(:placeholder-shown) {
  padding-top: 22px;
  padding-bottom: 10px;
}

.label{
  color: var(--kaz-textIcons-text-02);
}

.input-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.input-icon .icon{
  --kaz-base-base-09: var(--kaz-textIcons-text-03);
}

.input:not(:disabled) ~ .input-icon:hover > .icon {
  --kaz-base-base-09: var(--kaz-textIcons-text-02);
}

.input_size_L{
  padding: 16px 8px 16px 16px;
}

.input_size_M{
  padding: 12px 48px 12px 44px;
}

.input_size_S{
  padding: 6px 38px 6px 32px;
}

.input:not(:disabled) ~ .input-icon:last-child {
  cursor: pointer;
}

.error{
  color: var(--kaz-status-error-fill);
}

@media screen and (max-width: 568px) {
  .input__wrapper{
    width: 100%;
  }
}

</style>
