export const bookContent = {
  namespaced: true,

  state: () => ({
    chapters: [
      {
        id: 1,
        title: 'Глава 1',
        content: null,
        children: null
      },
      {
        id: 2,
        title: 'Глава 2',
        content: null,
        children: null
      },
      {
        id: 3,
        title: 'Глава 3',
        content: null,
        children: null
      },
    ],

    paragraphs: [
      {
        id: 1, 
        title: '§ 1. Основные понятия', 
        content: null, 
        children: null
      },
      {
        id: 2, 
        title: '§ 2. Основное свойство алгебраической дроби', 
        content: null, 
        children: null
      },
      {
        id: 3, 
        title: '§ 3. Сложение и вычитание алгебраических дробей с одинаковыми знаменателями', 
        content: null, 
        children: null
      },
    ],

    tasks: [
      {id: 10, title: 1, page: 6},
      {id: 12, title: 7, page: 6},
      {id: 13, title: 10, page: 6},
      {id: 14, title: 13, page: 16},
      {id: 15, title: 15, page: 16},
      {id: 16, title: 16, page: 16},
      {id: 17, title: 19, page: 26},
      {id: 18, title: 21, page: 26},
      {id: 19, title: 25, page: 36},
      {id: 20, title: 26, page: 36},
    ]
  }),

  getters: {
    getChapters: state => state.chapters,
    getParagraphs: state => state.paragraphs,
    getTasks: state => state.tasks,
  },

  actions: {
    createBookContent({getters}) {
      const paragraphs = getters.getParagraphs.map(par => ({
        ...par,
        content: [...getters.getTasks]
      }))
      
      const bookContent = getters.getChapters.map(chp => ({
        ...chp,
        children: [...paragraphs]
      }))

      return bookContent
    }
  }
}