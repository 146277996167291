export default {
  methods: {
    setMetaTags(title, description, canonicalUrl, ogTags) {

      // TITLE SETUP //
      document.title = title;

      // DESCRIPTION SETUP //
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      } else {
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.name = 'description';
        newMetaDescription.content = description;
        document.head.appendChild(newMetaDescription);
      }

      // CANONICAL URL SETUP //
      const seoCanonicalUrl = document.querySelector('link[rel="canonical"]');
      if (!seoCanonicalUrl) {
        seoCanonicalUrl = document.createElement('link');
        seoCanonicalUrl.setAttribute('rel', 'canonical');
        document.head.appendChild(canonicalUrl);
      }
      seoCanonicalUrl.setAttribute('href', canonicalUrl);

      // OG SETUP //
      const ogTitle = document.querySelector('meta[property="og:title"]');
      if (ogTitle) {
        ogTitle.setAttribute('content', ogTags.title);
      } else {
        const newOgTitle = document.createElement('meta');
        newOgTitle.property = 'og:title';
        newOgTitle.content = ogTags.title;
        document.head.appendChild(newOgTitle);
      }

      const ogDescription = document.querySelector('meta[property="og:description"]');
      if (ogDescription) {
        ogDescription.setAttribute('content', ogTags.description);
      } else {
        const newOgDescription = document.createElement('meta');
        newOgDescription.property = 'og:description';
        newOgDescription.content = ogTags.description;
        document.head.appendChild(newOgDescription);
      }

      const ogUrl = document.querySelector('meta[property="og:url"]');
      if (ogUrl) {
        ogUrl.setAttribute('content', ogTags.url);
      } else {
        const newOgUrl = document.createElement('meta');
        newOgUrl.property = 'og:url';
        newOgUrl.content = ogTags.url;
        document.head.appendChild(newOgUrl);
      }

      const ogImage = document.querySelector('meta[property="og:image"]');
      if (ogImage) {
        ogImage.setAttribute('content', ogTags.image);
      } else {
        const newOgImage = document.createElement('meta');
        newOgImage.property = 'og:image';
        newOgImage.content = ogTags.image;
        document.head.appendChild(newOgImage);
      }
    }
  }
};