<template>
  <div class="task">
    <div class="task__content">
      <task-shade
        v-if="shouldShowTaskShade"
        :isPremium="isPremium"
        @over-limit="onOverLimit"
      />
      <div v-else>
        <content-viewer-quill
          v-if="(contentType === CONTENT_TYPES.QUILL || contentType === CONTENT_TYPES.IMAGE) && taskId"
          :isTaskViewed="isViewed"
          :taskId="taskId"
          :taskType="taskType"
          @over-limit="onOverLimit"
          @change-task-state="data => $emit('change-task-state', data)"
        />
        <content-viewer-video
          v-else-if="(contentType === CONTENT_TYPES.VIDEO) && taskId"
          :taskId="taskId"
          :taskType="taskType"
          :isTaskViewed="isViewed"
          :isPremium="isPremium"
          @over-limit="onOverLimit"
          @change-task-state="data => $emit('change-task-state', data)"
        />
        <content-viewer-audio 
          v-else-if="(contentType === CONTENT_TYPES.AUDIO) && taskId"
          :taskId="taskId"
          :taskType="taskType"
          :isTaskViewed="isViewed"
          @over-limit="onOverLimit"
          @change-task-state="data => $emit('change-task-state', data)"
        />
        <content-viewer-html
          v-else-if="(contentType === CONTENT_TYPES.HTML) && taskId"
          :taskId="taskId"
          :taskType="taskType"
          :isTaskViewed="isViewed"
          :isPremium="isPremium"
          @over-limit="onOverLimit"
          @change-task-state="data => $emit('change-task-state', data)"
        />
      </div>
    </div>

    <div v-if="showReportLink" class="task__report">
      <link-report
        :link="reportLink"
        :isLeft="false"
        @click.prevent="showPopupReport = true"
      >
        Пожаловаться на содержимое
      </link-report>
      <popup-report
        :taskId="taskId"
        :taskType="taskType"
        :isPopupShown="showPopupReport"
        @closePopup="showPopupReport = false"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapGetters } from 'vuex';

import PopupReport from '@/components/UI/popups/PopupReport.vue';
import LinkReport from './LinkReport.vue';
import TaskShade from './TaskShade.vue';

import contentTypes from '../../utils/contentTypes.js';


export default {
  name: 'task-entity-2',

  components: {
    LinkReport,
    PopupReport,
    TaskShade,
    ContentViewerQuill: defineAsyncComponent({
      loader: () => import('../../components/ContentViewerQuill.vue')
    }),
    ContentViewerVideo: defineAsyncComponent({
      loader: () => import('../../components/ContentViewerVideo.vue')
    }),
    ContentViewerAudio: defineAsyncComponent({
      loader: () => import('../../components/ContentViewerAudio.vue')
    }),
    ContentViewerHtml: defineAsyncComponent({
      loader: () => import('../../components/ContentViewerHtml.vue')
    })
  },

  props: {
    nodeId: {
      type: String || Number,
      required: true
    },
    taskId: {
      type: String || Number,
      required: true
    },
    isViewed: {
      type: Boolean,
      default: false
    },
    isPremium: {
      type: Boolean,
      default: false
    },
    contentType: {
      type: Number,
      required: true
    },
    taskType: {
      type: Number,
      required: true
    },
    reportLink: {
      type: Object,
      required: true
    },
    timespamp: {
      type: Object
    }
  },

  emits: ['change-task-state', 'over-limit'],

  data() {
    return {
      CONTENT_TYPES: contentTypes,
      showPopupReport: false,
      showReportLink: true,
    }
  },

  watch: {
    taskType() {
      this.showReportLink = true;
    }
  },

  computed: {
    ...mapGetters({
      getRestTask: 'tasksRestInfo/getRestTask',
      getRestTaskPremium: 'tasksRestInfo/getRestTaskPremium'
    }),
    shouldShowTaskShade() {
      return !this.isViewed && (this.getRestTask === 0 || (this.isPremium && this.getRestTaskPremium === 0));
    },
  },

  methods: {
    addSolution() {
      this.$router.push({ name: 'editor', params: { tid: this.nodeId } });
    },
    onOverLimit(event) {
      this.$emit('over-limit');
      this.showReportLink = false;
    }
  }
}
</script>

<style scoped>
.task {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  /* background: var(--background-light, #F3F3FF); */
  background: var(--kaz-base-secondary);
}

.task__content,
.task__report {
  width: 100%;
}
</style>
