<template>
  <div class="input__wrapper">
    <label-text>{{ label }}</label-text>
    <select
      class="input input_type_select"
      :value="value"
      @input="updateValue"
    >
      <slot></slot>
    </select>
  </div>
</template>

<script>
import LabelText from '@/components/UI/texts/labels/LabelText.vue';

export default {
  name: 'select-component',
  components: {
    LabelText,
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: String,
  },
  data() {},
  methods: {
    updateValue(event) {
      this.$emit('update:value', event.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './input.scss';

.input__wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.input_type_select{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: 2px solid var(--background-light-20);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  color: var(--text-black);
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;

  background-image: url('@/assets/images/dropdown-icon.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 16px top 50%, 0 0;
  background-size: 20px auto, 100%;
}
.input_type_select::-ms-expand{
  display: none;
}
.input_type_select:hover{
  cursor: pointer;
}
</style>
