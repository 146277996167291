<template>
  <card-background v-if="showContent(nodesLoadingError)" class="content-wrapper">
    <spinner :isActive="isNodesLoading || !isNodesTreeCreated">
      <p
        v-if="nodesCreationError"
        class="body body_size_L"
        style="text-align: center;"
      >
        Что-то пошло не так. Попробуйте перезагрузить страницу.
      </p>
      <template v-else>
        <kaz-breadcrumbs :links="getBreadcrumbsLinks" :state="fetchState" />
        <div class="section__title">
          <h2 class="heading heading_size_h2">Содержание</h2>
          <input-search
            :value="inputData"
            @update:value="inputData = $event"
            :placeholder="'Поиск по заданиям'"
            :size="'S'"
          />
        </div>
        <content-tree class="content-tree">
          <content-node
            v-for="content in nodesTree"
            :node="content"
            :isOpen="true"
            :inputData="inputData"
            :nodeRouteName="getDestRouteName"
          />
        </content-tree>
      </template>
    </spinner>
  </card-background>

  <!-- <related-lessons
    :bookId="bookId"
    :nodeId="null"
    :srcLessons="prepareRelatedLessons"
  /> -->

  <!-- recommendation section -->
  <!-- <recommendations-container :title="'Дополнительные материалы'">
    <swiper
      :space-between="24"
      :modules="modules"
      :navigation="true"
    >
      <swiper-slide
        v-for="recommendationBook in recommendationBooks"
        :key="recommendationBook.id"
      >
        <book-card
          :id="recommendationBook.id"
          :bookTitle="recommendationBook.bookTitle"
          :bookImg="recommendationBook.bookImg"
          :bookDescription="recommendationBook.bookDescription"
          :authors="recommendationBook.authors"
        />
      </swiper-slide>
    </swiper>
  </recommendations-container> -->

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { defineAsyncComponent } from 'vue';

//import BookCard from '@/components/cards/BookCard';
import CardBackground from '@/components/UI/card/CardBackground.vue';
import KazBreadcrumbs from '@/components/KazUI/molecules/breadcrumbs';
import InputSearch from '@/components/KazUI/atoms/inputs/search';
import RelatedLessons from '@/views/tasks/components/RelatedLessons.vue';

import useLoadingStates from '@/hooks/loading/useLoadingStates.js';
import useNodesTree from '@/hooks/books/useNodesTree.js';
import { showNodeContent } from '@/hooks/books/utils.js';
import useEditorIntro from '@/hooks/routes/useEditorIntro.js';
import { fetchBook } from '@/services/TaskReader';
import { guessLevelId } from '@/views/tasks/utils/breadcrumbs.js';
import { createMetadataWithToken } from '@/services/utils';
import { EntitiesServiceClient } from '@/generated/entities/entities_service_grpc_web_pb.js';
import { fetchStreamData } from '@/utils/loaders';


const SERVICE_URL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
const entitiesService = new EntitiesServiceClient(SERVICE_URL, null, null);


export default {
  name: 'book-content',

  components: {
    CardBackground,
    KazBreadcrumbs,
    InputSearch,
    RelatedLessons,
    ContentTree: defineAsyncComponent({
      loader: () => import('@/components/UI/ContentTree')
    }),
    ContentNode: defineAsyncComponent({
      loader: () => import('@/components/UI/ContentNode')
    }),
  },

  props: {
    bookId: {
      type: String,
      default: null,
    },
    bookInfo: {
      type: Object,
      required: true,
    },
    nodesList: {
      type: Object,
      required: true
    },
    isNodesLoading: {
      type: Boolean,
      required: true
    },
    nodesLoadingError: {
      type: [Object, null],
      required: true
    },
    // noResultsFound: false,
  },

  setup(props) {
    console.log('PRESTART CREATION', Date.now());
    const { LOADING_STATES } = useLoadingStates();
    const {nodesTree, isNodesTreeCreated, nodesCreationError} = useNodesTree(props.nodesList);
    const {getDestRouteName} = useEditorIntro('task');
    const showContent = showNodeContent;
    return {
      LOADING_STATES,
      nodesTree,
      isNodesTreeCreated,
      nodesCreationError,
      showContent,
      getDestRouteName
    }
  },

  data() {
    return {
      recommendationBooks: [],
      inputData: '',
      fetchState: this.LOADING_STATES.INIT,
    }
  },

  computed: {
    ...mapGetters({
      getGrades: 'gradeData/getGrades',
      getCourses: 'courseData/getCourses',
      getGradeState: 'gradeData/getState',
      getCourseState: 'courseData/getState',
      getRelatedLessons: 'relatedLessons/getLessons',
    }),
    loadingStates() {
      return [this.getGradeState, this.getCourseState, this.fetchState]
    },
    getBreadcrumbsLinks() {
      const homeLink = { path: { name: 'tasks' }, title: 'Решения' };
      const links = [ homeLink ];
      if (this.isLoadReady(this.loadingStates)) {
        const lvlId = guessLevelId(this.book.levelsList, this.book.name);
        const subjId = this.book.subjectId;
        // Level link
        if (lvlId) {
          const lvlTitle = this.getGrades.find(lvl => lvl.id === lvlId)?.title || 'Неизвестный класс';
          links.push({
            path: { name: 'course', query: { level: lvlId } },
            title: lvlTitle
          });
        }
        // Subject link
        if (subjId) {
          const subjTitle = this.getCourses.find(sub => sub.id === +subjId)?.title || 'Неизвестный предмет';
          links.push({
            path: { name: 'course', query: { subject: subjId } },
            title: subjTitle
          });
        }
        // Book link
        links.push({
          path: { name: 'book-content', params: { bid: this.bookId } },
          title: this.book.name
        });
      }
      return links
    },
    prepareRelatedLessons() {
      // define initial lessons state: selected/non-selected
      return Object.values(this.getRelatedLessons || {});
    }
  },

  methods: {
    ...mapActions({
      createRandomBooks: 'bookData/createRandomBooks',
    }),
    ...mapMutations({
      setRelatedLessons: 'relatedLessons/setLessons'
    }),
    isLoadReady(states) {
      let isReady = true;
      for (const state of states) {
        if (state !== this.LOADING_STATES.LOADED) {
          isReady = false;
          break;
        }
      }
      return isReady
    },    
    async fetchBookInfo() {
      try {
        this.fetchState = this.LOADING_STATES.LOADING;
        const response = await fetchBook(this.bookId);
        this.book = response.toObject().book;
        this.fetchState = this.LOADING_STATES.LOADED;
      } catch (error) {
        this.fetchState = this.LOADING_STATES.ERROR;
        console.error(error);
      }
    },

    async loadLessons(subjectId, levelsIds, typeId = 125) {
      /**
       * typeId:
       *  125 - chapters for given subject and level
       *  123 - lessons for given subject adn all levels
       */
      console.warn(`Load lessons: subject=${subjectId}, level=${levelsIds}, typeID=${typeId}`);
      if (!subjectId) return
      try {
        const request = new proto.kazatel.entities.EntitiesRequest();
        if (typeId === 125) {
          request.setLevelsList(levelsIds);
        }
        request.setTypesList([typeId]);
        request.setSubjectsList([subjectId]);
        request.setStatusList([1]);
        request.setPageSize(10);
        const metadata = createMetadataWithToken();
        const stream = entitiesService.entities(request, metadata);
        const streamData = await fetchStreamData(stream);
        let programId;
        let programRootNode;
        streamData.forEach(response => {
          programId = response.getId();
          programRootNode = response.toObject()
          console.log('Lessons Root Node:', programRootNode);
        })
        if (programId) {
          await this.fetchLessonNodes(programId, levelsIds, subjectId);
        } 
        console.warn('Lessons loaded');
      } catch (err) {
        console.error('Loading [Lessons]:', err);
      }
    },

    async fetchLessonNodes(programId, levelsIds, subjectId) {
      try {
        if (!programId) {
          throw new Error(`Lesson node ID is empty: ${programId}`)
        }
        const metadata = createMetadataWithToken();
        const requestNodes = new proto.kazatel.entities.NodesRequest();
        requestNodes.setEntityId(programId);
        requestNodes.setLevelsList(levelsIds);
        const streamNodes = entitiesService.nodes(requestNodes, metadata);

        const lessons = {};
        let firstLessonId = null;
        const streamData = await fetchStreamData(streamNodes);

        streamData.forEach(response => {
          // console.log('GGGGGGGGGGGGG', response.toObject());
          const lessonId = response.getId();
          if (!response.getPrevId()) {
            firstLessonId = lessonId;
          }
          lessons[lessonId] = {
            id: lessonId,
            nextId: response.getNextId(),
            parentId: response.getParentId(),
            title: response.getTitle(),
            content: [],
            type: response.getType(),
            image: response.getViewUrl()
          };
        });

        /*
        // sorting doesn't work now because of prev/next ids are broken
        const sortedLessons = [];
        while (firstLessonId) {
          sortedLessons.push(lessons[firstLessonId]);
          firstLessonId = lessons[firstLessonId].nextId;
        }*/
        this.setRelatedLessons({
          subjectId: subjectId,
          levelId: levelsIds,
          lessons: lessons,
        });
        console.log('Fetch lesson nodes has been done', lessons.value);
      } catch(err) {
        // console.error('Fetch lesson nodes:', err);
        throw err
      } 
    }
  },

  async created() {
    this.fetchBookInfo();
    await this.loadLessons(this.bookInfo.subject, this.bookInfo.level);
    console.log('BOOK CONTENT CREATED');
    
  },

  async mounted() {
    // this.recommendationBooks = await this.createRandomBooks(bookNum);
  }

}
</script>

<style scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 32px;
  width: 100%;
  min-height: 50vh;
  padding: 1.5rem;
}

.section__title{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .input__search{
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 568px) {
  .section__title{
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
}

.swiper-slide {
  width: 60% !important;
}

@media screen and (min-width: 480px) {
  .swiper-slide {
    width: 40% !important;
  }
}

@media screen and (min-width: 576px) {
  .swiper-slide {
    width: 30% !important;
  }
}

@media screen and (min-width: 992px) {
  .swiper-slide {
    width: 25% !important;
  }
}
</style>
