import STATES from "@/utils/states";
import TASK_TYPES from "@/views/tasks/utils/taskTypes";
import { ACTION_TYPE, MODERATION_STATUS } from '@/utils/moderation';
import { fetchMethodData } from "@/utils/loaders.js";
import { extractTokenInfo } from '@/utils/auth';
import { isDeltaEmpty } from '@/utils/editor';
import { TaskReaderClient } from "@/generated/taskreader/taskreader_grpc_web_pb.js";

const cssLogSuccess =
  "color: black; background-color: lightgreen; padding: 4px;";
const preservedDataKey = 'preservedEditor'

/*
This store intented to serve all (not only quill) media content: quill, video, audio.
Containers that store content are initContent and updatedContent.

Storage containers have the same format:
{
  taskID: {
    taskTypeID: contentCore
  }
}

Current task type id's: SOLUTION, CONDITION, AUDIO, VIDEO.
WARNING:  taskType is not the same as content type this is just bad naming. 
          taskType should be called as entityType/nodeType.

Actual data are stored in `contentCore` object that has the following structure:
{
  contentData: Delta | Href,
  contentId: String,
  ?contentMeta: Object,
  ?anyOtherData: Any,
}

Property `contentData` is required and its value type depends on taskTypeID:
- for SOLUTION and CONDITION is a `Delta` object;
- for AUDIO and VIDEO is a string (resource href).

Property `contentMeta` is required for AUDIO and VIDEO only as
it is used for backend saving:
{ 
  name, 
  size, 
  lastModified, 
  storageId 
}

Note: pay attention on `contentCore` for AUDIO/VIDEO. This doesnt contain actual
media data (bytes)! The bytes are reflected in storageId as a result of 
multi stage file uploading to temporary S3 storage. Moreover this storageId 
is required for final save. Actual bytes transmition is performed in 
ContentEditor[Video,Audio].vue components (necessary code refactoring).
*/


export const quillData = {
  namespaced: true,

  state: () => ({
    dataState: STATES.INIT,  // i.e. loading state
    initContent: {},
    updatedContent: {},
    // This very important for creating first task
    taskId: null,
    taskVersion: 0,
    book: null,
    isPreserved: false
  }),

  getters: {
    getAllContents(state) {
      return {
        initContent: state.initContent,
        updatedContent: state.updatedContent,
      };
    },
    getInitContent(state) {
      return state.initContent
    },
    getUpdatedContent(state) {
      return state.updatedContent
    },
    getContentById(state) {
      return (id) => state.initContent[id];
    },
    getState(state) {
      return state.dataState;
    },
    getTaskVersion(state) {
      return state.taskVersion;
    },
    getTaskId(state) {
      return state.taskId;
    },
    getBook(state) {
      return state.book;
    },
    getPreserveStatus(state) {
      return state.isPreserved
    }
  },

  mutations: {
    setContent(state, { taskId, taskType, delta }) {
      if (!(taskId in state.initContent)) {
        state.initContent[taskId] = {};
      }
      if (taskType === TASK_TYPES.CONDITION || taskType === TASK_TYPES.SOLUTION) {
        let trueDelta = delta.contentData || null;
        delta.contentData = isDeltaEmpty(trueDelta) ? null : trueDelta;
      }
      state.initContent[taskId][taskType] = delta;
    },
    updateContent(state, { taskId, taskType, delta }) {
      if (!state.updatedContent[taskId]) {
        state.updatedContent = { [taskId]: { [taskType]: {} } };
      }
      if (!state.updatedContent[taskId]?.[taskType]) {
        state.updatedContent[taskId][taskType] = {};
      }
      if (taskType === TASK_TYPES.CONDITION || taskType === TASK_TYPES.SOLUTION) {
        state.updatedContent[taskId][taskType].contentData = delta;
      } else {
        state.updatedContent[taskId][taskType] = delta;
      }
    },
    updateContentMeta(state, { taskId, taskType, meta }) {
      try {
        if (taskId in state.initContent) {
          _updateContentMeta(state.initContent, { taskId, taskType, meta });
        }
        if (taskId in state.updatedContent) {
          _updateContentMeta(state.updatedContent, { taskId, taskType, meta });
        }
      } catch (error) {
        console.warn(error);
      }
    },
    copyTask(state, { fromTaskId, toTaskId }) {
      try {
        if (fromTaskId in state.initContent) {
          state.initContent[toTaskId] = state.initContent[fromTaskId];
        }
        if (fromTaskId in state.updatedContent) {
          state.updatedContent[toTaskId] = state.updatedContent[fromTaskId]; 
        }
      } catch (error) {
        console.error(error);
      }
    },
    deleteTask(state, taskId) {
      try {
        if (taskId in state.initContent) {
          delete state.initContent[taskId];
        }
        if (taskId in state.updatedContent) {
          delete state.updatedContent[taskId]; 
        }
      } catch (error) {
        console.error(error);
      }
    },
    setPreservedContent(state, preservedContent) {
      state.initContent = preservedContent;
    },
    updatePreservedContent(state, preservedContent) {
      state.updatedContent = preservedContent;
    },
    setState(state, newState) {
      state.dataState = newState;
    },
    setTaskVersion(state, newVersion) {
      state.taskVersion = newVersion;
    },
    setTaskId(state, newId) {
      state.taskId = newId;
    },
    resetState(state) {
      state.dataState = STATES.INIT;
      state.initContent = {};
      state.updatedContent = {};
    },
    setBook(state, newBook) {
      state.book = newBook;
    },
    setPreservedStatus(state, newStatus) {
      state.isPreserved = newStatus;
    }
  },

  actions: {
    setSynchronizedContent({ commit }, { taskId, taskType, delta }) {
      try {
        commit('setContent', { taskId, taskType, delta });
        commit('updateContent', { taskId, taskType, delta });
      } catch (error) {
        throw error;
      }
    },
    async preserveData({getters}, {bookId, nodeId, taskId}) {
      const states = {bookId, nodeId, taskId};
      const { initContent, updatedContent } = getters.getAllContents;
      states.initContent = initContent;
      states.updatedContent = updatedContent;
      localStorage.setItem(preservedDataKey, JSON.stringify(states));
      console.log('Editor data has been preserved in localStorage');
    },

    async restorePreservedData({commit}) {
      const preservedState = JSON.parse(localStorage.getItem(preservedDataKey));
      if (!preservedState) {
        console.log('Nothing to restore');
        return false
      }
      const {initContent, updatedContent} = preservedState;
      commit('setPreservedContent', initContent);
      commit('updatePreservedContent', updatedContent);
      commit('setPreservedStatus', true);
      console.log('Preserved data has been restored');
      return true
    },

    async freePreservedData() {
      localStorage.removeItem(preservedDataKey);
      console.log('Preserved data has been removed');
    },

    async saveSingleTask(
      { getters, commit }, 
      { nodeId, taskId, taskType, actionType, contentExtractor }
    ) {
      console.log(`Editor action [${actionType}]:`, taskId, taskType);

      if (typeof contentExtractor !== 'function') {
        throw new Error(`contentExtractor has ${typeof contentExtractor} type but it must be a function.`)
      }
      
      const { initContent, updatedContent } = getters.getAllContents;
      // if (!(taskId in initContent)) {
      //   throw new Error(`Task ID ${taskId} is not found in storage.`);
      // }

      const initTasks = taskId in initContent ? initContent[taskId] : null;
      const updatedTasks = taskId in updatedContent ? updatedContent[taskId] : null;

      const initContentId = initTasks?.[taskType]?.contentId || null;
      const updatedContentId = updatedTasks?.[taskType]?.contentId || null;
      
      let contentId = null;
      if (initContentId) {
        contentId = initContentId;
      } else if (updatedContentId) {
        contentId = updatedContentId;
      }

      if (actionType === ACTION_TYPE.DELETE) {
        if (!contentId) {
          throw new Error('Попытка удалить не существующий контент')
        }
        try {
          await deleteTaskContent(contentId);
        } catch (error) {
          throw error
        }
        return
      }

      let initTaskDelta = null;
      let updatedTaskDelta = null;
      if (initTasks && taskType in initTasks) {
        initTaskDelta = contentExtractor(initTasks, taskType);
      }
      if (updatedTasks && taskType in updatedTasks) {
        updatedTaskDelta = contentExtractor(updatedTasks, taskType);
      }

      // next block saves content even it's empty because of mutation
      // setContent always is called in ContentViewerQuill.vue and
      // sets 'empty' Delta in worst case
      let taskForSave = null;
      if (updatedTaskDelta) {
        taskForSave = updatedTaskDelta;
      } else if (initTaskDelta) {
        taskForSave = initTaskDelta;
      }

      if (!taskForSave) {
        console.warn('Content is empty. Skip saving...');
        throw new Error('Мы не сохраняем пустоту');
      }

      try {
        if (taskId !== "0") {
          console.log('taskId:', taskId, 'contentId:', contentId);
          const response = await updateTaskContent(
            taskForSave, 
            taskId,
            contentId,
            taskType, 
            actionType
          );
          const statusIdNew = 1;
          const contentIdNew = response.value;
          commit('updateContentMeta', {
            taskId, taskType, meta: { statusId: statusIdNew, contentId: contentIdNew }
          });
        } else {
          let newTaskId;
          // It's very important to reset taskId before start edit
          // because currently this storage supports only one taskId at a time
          if (getters.getTaskId !== '0') {
            newTaskId = getters.getTaskId;
          } else {
            const taskReaderService = new TaskReaderClient(
              process.env.VUE_APP_REGISTRATION_SERVICE_URL,
              null,
              null
            );
            const tokens = extractTokenInfo();
            const metadata = { token: tokens.accessToken.token };
            const requestCreate = new proto.kazatel.tasks.TaskCardCreate();
            requestCreate.setNodeId(nodeId);
            const createResponse = await fetchMethodData(
              taskReaderService,
              "create",
              { request: requestCreate, metadata }
            );
            const task = createResponse.toObject().task;
            newTaskId = task.taskId;
            commit("setTaskId", newTaskId);
          }

          // commit('copyTask', { fromTaskId: taskId, toTaskId: newTaskId });
          // commit('deleteTask', taskId);
          const response = await updateTaskContent(
            taskForSave, 
            newTaskId, 
            null,
            taskType, 
            actionType
          );
          const statusIdNew = 1;
          const contentIdNew = response.value;
          commit('updateContentMeta', {
            taskId, taskType, meta: { statusId: statusIdNew, contentId: contentIdNew }
          });
        }
      } catch (error) {
        commit("setState", STATES.ERROR);
        console.error("task update failed:", error);
        throw new Error(error);
      }
    },

    async saveAllTasks({ getters, commit, dispatch }, { bookId, nodeId, taskId, contentExtractor }) {
      console.log("saving whole task ID:", taskId);

      if (typeof contentExtractor !== 'function') {
        throw new Error(`contentExtractor has ${typeof contentExtractor} type but it must be a function.`)
      }

      commit("setState", STATES.LOADING);

      const { initContent, updatedContent } = getters.getAllContents;
      if (!(taskId in initContent)) {
        // throw new Error(`Task ID ${taskId} is not found in storage.`);
        console.warn(`Task ID ${taskId} is not found in initial state.`);
      }

      const initTasks = taskId in initContent ? initContent[taskId] : null;
      const updatedTasks =
        taskId in updatedContent ? updatedContent[taskId] : null;
      
      // WARNING: Presence of key is a trigger for saving content.
      const tasksForSave = {};
      for (const taskType in TASK_TYPES) {
        let initTaskDelta = null;
        let updatedTaskDelta = null;
        if (initTasks && TASK_TYPES[taskType] in initTasks) {
          initTaskDelta = contentExtractor(initTasks, TASK_TYPES[taskType]);
        }
        if (updatedTasks && TASK_TYPES[taskType] in updatedTasks) {
          updatedTaskDelta = contentExtractor(updatedTasks, TASK_TYPES[taskType]);
        }

        // WARNING: this logic is extremely important!
        // As saving will be executed if tasksForSave has
        // corresponding taskType key even if its value is null!
        if (updatedTaskDelta) {
          tasksForSave[taskType] = updatedTaskDelta;
        } else if (initTaskDelta) {
          tasksForSave[taskType] = initTaskDelta;
        } else {
          // If there is no content of that type
          // DONT add corresponding key!!!
        }
      }

      if (!Object.keys(tasksForSave).length) {
        throw new Error('Мы не сохраняем пустоту')
      }

      const tokens = extractTokenInfo();
      const metadata = { token: tokens.accessToken.token };
      const taskReaderService = new TaskReaderClient(
        process.env.VUE_APP_REGISTRATION_SERVICE_URL,
        null,
        null
      );

      console.log("tasks for save:", tasksForSave);
      try {
        await dispatch('loadBook', { bookId });
      } catch (error) {
        console.error('Reload book:', error);
      }

      if (taskId !== "0") {
        await updateTask(tasksForSave, taskId, getters.getTaskVersion + 1, { commit, getters });
      } else {
        try {
          const requestCreate = new proto.kazatel.tasks.TaskCardCreate();
          requestCreate.setNodeId(nodeId);

          const createResponse = await fetchMethodData(
            taskReaderService,
            "create",
            { request: requestCreate, metadata }
          );
          const task = createResponse.toObject().task;
          // the task ID must be synchronised with component's taskId
          // better to move all logic in composition API
          const newTaskId = task.taskId;
          commit("setTaskVersion", 1);
          commit("setTaskId", newTaskId);
          await updateTask(tasksForSave, newTaskId, 1, { commit, getters });
        } catch (error) {
          commit("setState", STATES.ERROR);
          console.error("tasks creating failed:", error);
          throw new Error(error);
        }
      }
    },

    async loadBook({ commit }, { bookId }){
      try {
        const book = await fetchBook(bookId);
        commit('setBook', book);
      } catch (error) {
        console.error(error);
      }
    }
  }
};

async function updateTask(tasksForSave, taskId, taskVersion, { commit, getters }) {
  const requestUpdate = new proto.kazatel.books.TaskCardUpdate();
  requestUpdate.setTaskId(taskId);
  if (taskVersion) {
    requestUpdate.setVersionId(taskVersion);
  }
  // Временно все новые решения записываются с атрибутом Премиум
  // requestUpdate.setAttrPremium(getters.getBook.attrPremium);
  requestUpdate.setAttrPremium(true);

  for (const taskType in tasksForSave) {
    if (!tasksForSave[taskType]) {
      console.log(`Task with type ${taskType} is empty, skipping...`);
      continue;
    }
    let taskContent = new proto.kazatel.books.TaskContent();
    switch (TASK_TYPES[taskType]) {
      case TASK_TYPES.SOLUTION:
        taskContent.setType(2);
        taskContent.setContentData(JSON.stringify(tasksForSave[taskType]));
        requestUpdate.addContentSolution(taskContent);
        console.log("set solution json:", tasksForSave[taskType]);
        break;
      case TASK_TYPES.CONDITION:
        taskContent.setType(2);
        taskContent.setContentData(JSON.stringify(tasksForSave[taskType]));
        requestUpdate.addContentCondition(taskContent);
        console.log("set condition json:", tasksForSave[taskType]);
        break;
      case TASK_TYPES.AUDIO:
        const { 
          name: audioName, 
          size: audioSize, 
          storageId: audioStorageId 
        } = tasksForSave[taskType];
        
        const audioMeta = new proto.kazatel.books.TaskContentMeta();
        audioMeta.setNativeName(audioName);
        audioMeta.setSize(audioSize);

        taskContent.setType(3);
        taskContent.setContentData(audioStorageId);
        taskContent.setContentMeta(audioMeta);

        requestUpdate.addContentSolution(taskContent);
        console.log("set solution audio", tasksForSave[taskType]);
        break;
      case TASK_TYPES.VIDEO:
        const { 
          name: videoName, 
          size: videoSize, 
          storageId: videoStorageId 
        } = tasksForSave[taskType];

        const videoMeta = new proto.kazatel.books.TaskContentMeta();
        videoMeta.setNativeName(videoName);
        videoMeta.setSize(videoSize);

        taskContent.setType(4);
        taskContent.setContentData(videoStorageId);
        taskContent.setContentMeta(videoMeta);

        requestUpdate.addContentSolution(taskContent);
        console.log("set solution video", tasksForSave[taskType]);
        break;
      default:
        console.warn(`Saving task of type ${taskType} is not implemented`);
    }
  }

  const taskReaderService = new TaskReaderClient(
    process.env.VUE_APP_REGISTRATION_SERVICE_URL,
    null,
    null
  );
  const tokens = extractTokenInfo();
  const metadata = { token: tokens.accessToken.token };

  const updateResponse = await fetchMethodData(taskReaderService, "update", {
    request: requestUpdate,
    metadata,
  });
  console.log(`%cTask updated:`, cssLogSuccess, updateResponse.toObject());
  commit("setState", STATES.LOADED);
}

async function updateTaskContent(taskContent, taskId, contentId, taskType, actionType) {
  const request = new proto.kazatel.books.TaskContent();
  request.setPremium(true);
  if (taskId) {
    request.setTaskId(taskId);
  }
  if (contentId) {
    request.setId(contentId);
  }

  // Set 'moderation' status
  let statusId = 0;
  if (actionType === ACTION_TYPE.PUBLISH) {
    statusId = MODERATION_STATUS.NEW;
  } else if (actionType === ACTION_TYPE.DRAFT) {
    statusId = MODERATION_STATUS.DRAFT;
  }
  request.setStatusId(statusId);

  // Set task type related properties
  let contentType = null;
  let formatType = null;
  let nativeName = null;
  let contentSize = null;
  let contentData = null;
  switch (taskType) {
    case TASK_TYPES.SOLUTION:
      contentType = 2;
      formatType = 2;
      contentData = JSON.stringify(taskContent);
      break;
    case TASK_TYPES.CONDITION:
      contentType = 1;
      formatType = 2;
      contentData = JSON.stringify(taskContent);
      break;
    case TASK_TYPES.AUDIO:
      contentType = 2;
      formatType = 3;
      nativeName = taskContent.name;
      contentSize = taskContent.size;
      contentData = taskContent.storageId;
      break;
    case TASK_TYPES.VIDEO:
      contentType = 2;
      formatType = 4;
      nativeName = taskContent.name;
      contentSize = taskContent.size;
      contentData = taskContent.storageId;
      break;
    default:
      console.warn(`Unknown task type = ${taskType}`);
      break;
  }
  request.setType(contentType);
  request.setContentData(contentData);

  // Set metadata
  const contentMeta = new proto.kazatel.books.TaskContentMeta();
  contentMeta.setType(formatType);
  if (nativeName) {
    contentMeta.setNativeName(nativeName);
  }
  if (contentSize) {
    contentMeta.setSize(contentSize);
  }
  request.setContentMeta(contentMeta);

  try {
    const taskReaderService = new TaskReaderClient(
      process.env.VUE_APP_REGISTRATION_SERVICE_URL, null, null
    );
    const tokens = extractTokenInfo();
    const metadata = { token: tokens.accessToken.token };
    const updateResponse = await fetchMethodData(
      taskReaderService, 
      "updateContent", 
      { request, metadata }
    );
    console.log(`%cTask updated:`, cssLogSuccess, updateResponse.toObject());
    return updateResponse.toObject();
  } catch (error) {
    throw error
  }
}

async function deleteTaskContent(contentId) {
  console.log('deleteTaskContent: ', contentId);
  try {
    const taskReaderService = new TaskReaderClient(
      process.env.VUE_APP_REGISTRATION_SERVICE_URL, null, null
    );
    const tokens = extractTokenInfo();
    const metadata = { token: tokens.accessToken.token };
    const request = new proto.google.protobuf.StringValue;
    request.setValue(contentId);

    const deleteResponse = await fetchMethodData(
      taskReaderService, 
      "deleteContent", { request, metadata }
    );
    console.log(`%cTask deleted:`, cssLogSuccess, deleteResponse.toObject());
  } catch (error) {
    throw error
  }
}

async function fetchBook(bookId) {
  console.log('fetchBook: bookID =', bookId);
  try {
    const taskReaderService = new TaskReaderClient(
      process.env.VUE_APP_REGISTRATION_SERVICE_URL, 
      null, 
      null
    );
    const requestBook = new proto.kazatel.books.Book();
    requestBook.setId(bookId);
    const tokens = extractTokenInfo();
    const metadata = { 'token': tokens.accessToken.token };
    // taskReaderService.book(requestBook, metadata, resultHandlerBook);
    const bookResponse = await fetchMethodData(
      taskReaderService,
      "book",
      { request: requestBook, metadata }
    );
    const book = bookResponse.toObject().book;
    console.log(book);
    return book;
  } catch (e) {
    switch (err.code) {
      case 2:
        console.error('Сервис недоступен\n' + err.message);
        break;
      case 6:
        console.error('Пользователь с указанными данными уже зарегистрирован');
        break;
      case 14:
        console.error('Сервис регистрации недоступен\n' + err.message);
        break;
      default:
        console.error(err.code);
        console.error(err.message);
    }
  }
}

function _updateContentMeta(contentStore, { taskId, taskType, meta }) {
  if (taskId in contentStore && taskType in contentStore[taskId]) {
    for (const [key, val] of Object.entries(meta)) {
      // These 2 keys are skipped because they contain actual content data for quill and media correspondingly
      if (key !== 'contentData' && key !== 'contentMeta') {
        contentStore[taskId][taskType][key] = val;
      }
    }
  } else {
    throw new Error(`Can't update meta: taskId [${taskId}] or taskType [${taskType}] is not exist in storage`);
  }
}