import { ref, onMounted, computed, isRef, toRef, watch } from 'vue';
import { useStore } from 'vuex';

import { createTree } from './utils.js';


export default function useNodesTree(nodesList) {
  /*
  WARNING: at this time nodesList has to be non-ref.
  */

  console.log(`PRESTART CREATION PROP TYPE: `, nodesList);
  
  const store = useStore();
  const isNodesTreeCreated = ref(false);
  const nodesCreationError = ref(false);

  watch(nodesList, (oldVal, newVal) => {
    let diff = false;
    if (Object.keys(oldVal).length !== Object.keys(newVal).length) {
      diff = true;
      console.log('DIFF LEN: ', Object.keys(oldVal).length, Object.keys(newVal).length);
    } else {
      for(const k in oldVal) {
        if (!(k in newVal)) {
          diff = true;
          console.log('DIFF KEY:', k, oldVal[k]);
        } 
      }
    }
    console.log('DIFF FINAL:', diff);
    console.log('STRINGIFIED DIFF:', JSON.stringify(oldVal) === JSON.stringify(newVal));
  },
  { immediate: false }
  );

  const createNodesTree = () => {
    try {
      if (Object.keys(toRef(nodesList).value).length) {
        console.log('COMPUTED TREE:', nodesList);
        isNodesTreeCreated.value = false;
        nodesCreationError.value = false;
        const nodes = [];
        const clonedNodesList = structuredClone(nodesList);
        // WARNING: next function modifies passed object
        const rootNode = createTree(clonedNodesList, store.getters['bookTree/getNodeTypes']);

        if (rootNode) {
          for (const key in rootNode.children) {
            nodes.push(rootNode.children[key]);
          }
        }

        isNodesTreeCreated.value = true;
        return nodes;
      }
      else {
        console.log('COMPUTED TREE:', nodesList);
      }
    } catch(err) {
      console.error('createNodesTree', err);
      isNodesTreeCreated.value = true;
      nodesCreationError.value = true;
    }
  };

  console.log(`PRESTART CREATION PROP TYPE: `, nodesList);
  // const nodesTree = computed(createNodesTree);
  const nodesTree = createNodesTree();

  return {
    nodesTree, 
    isNodesTreeCreated, 
    nodesCreationError,
  };
}