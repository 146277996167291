<template>
  <div class="card shadow">
    <button 
      class="btn btn-primary w-100 d-md-none"
      type="button" 
      data-bs-toggle="collapse" 
      data-bs-target="#collapseExample" 
      aria-expanded="false" aria-controls="collapseExample"
      >
        <font-awesome-icon :icon="['fas', 'sliders']"/>
        Содержание
    </button>

    <div class="accordion collapse show" id="collapseExample">
      <!-- list items -->
      <div class="accordion-item" v-for="grade in data" :key="grade.id">
        <!-- title -->
        <h2 class="accordion-header" :id="makeHeading(grade.id)">
          <button 
            class="accordion-button d-flex"
            :class="{ collapsed : activeGradeId !== grade.id}"
            type="button" 
            data-bs-toggle="collapse" 
            :data-bs-target="makeCollapse(grade.id, asId=true)"
            aria-expanded="true" 
            :aria-controls="makeCollapse(grade.id)"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1_5453)">
                <path d="M-1.99958 20.352L2.59643 17.5975C2.78251 17.4691 2.98144 17.3604 3.19001 17.2731L3.66213 17.0756C4.96162 16.5321 6.37979 16.3346 7.77835 16.5024L8.84928 16.6309C8.97533 16.646 9.10238 16.6511 9.22923 16.6462L10.6007 16.593C11.1691 16.571 11.6481 17.0133 11.6711 17.5816C11.6892 18.0269 11.4193 18.4334 11.0019 18.5894L10.4874 18.7818C10.2498 18.8706 9.996 18.9079 9.74288 18.8912L7.90191 18.7699C7.68305 18.7554 7.49759 18.9291 7.49759 19.1484C7.49759 19.3444 7.64696 19.5082 7.84217 19.5262L9.53225 19.6818C9.92188 19.7176 10.3141 19.6508 10.6699 19.488L11.7535 18.992C12.0169 18.8859 12.0887 18.5894 12.3163 18.4862L14.152 17.9885C14.4621 17.9044 14.7917 17.9306 15.0846 18.0627C15.3174 18.1676 15.4964 18.3641 15.5793 18.6056L15.5942 18.6491C15.7184 19.0114 15.6314 19.4127 15.3684 19.6912L15.2321 19.8355C15.0652 20.0121 14.8813 20.1704 14.6785 20.3044C13.3963 21.1518 10.9085 22.6284 8.69454 23.0968C7.52152 23.3953 5.07736 22.7601 3.85908 22.3499C3.68386 22.2909 3.49103 22.3197 3.34368 22.4314L0.706766 23.8897C0.469815 24.0693 0.132532 24.0251 -0.0501437 23.7905L-2.11967 21.1331C-2.31161 20.8866 -2.2567 20.5294 -1.99958 20.352Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.7639 3.49119L11.1566 0.767786C11.9988 0.523577 12.8928 0.521392 13.7362 0.761482L23.3212 3.48991C24.229 3.74831 24.2246 5.03638 23.3151 5.28866L23.2419 5.30898L23.2419 7.302C23.2419 7.45742 23.2991 7.60739 23.4026 7.7233C23.5062 7.83921 23.5634 7.98918 23.5634 8.1446V8.65343C23.5634 8.77941 23.5211 8.90176 23.4434 9.0009C23.3188 9.15983 23.2888 9.37333 23.3649 9.56043L23.781 10.584C23.8309 10.7069 23.7405 10.8412 23.6079 10.8412H22.004C21.8709 10.8412 21.7805 10.706 21.8314 10.5829L22.256 9.55657C22.3326 9.3714 22.3059 9.15957 22.1858 8.99919C22.1122 8.90097 22.0725 8.78156 22.0725 8.65884V8.13986C22.0725 7.98716 22.1269 7.83946 22.226 7.7233C22.3251 7.60714 22.3796 7.45944 22.3796 7.30673L22.3796 5.54816L13.7057 7.9542C12.8814 8.18284 12.0102 8.18076 11.187 7.94818L1.77005 5.28747C0.866331 5.03213 0.861951 3.75271 1.7639 3.49119ZM8.37528 8.95358L6.14304 8.34984C5.5492 8.18922 4.96492 8.63646 4.96491 9.25163L4.96484 12.3904C4.96483 12.8155 5.25184 13.187 5.66319 13.2944L8.50953 14.037C11.0917 14.7108 13.8021 14.723 16.3902 14.0726L19.5052 13.2897C19.9205 13.1853 20.2117 12.8119 20.2117 12.3837L20.2117 9.23998C20.2117 8.62839 19.6338 8.18175 19.042 8.33592L16.5255 8.9915C13.8517 9.68803 11.0425 9.67496 8.37528 8.95358Z"/>
              </g>
              <defs>
                <clipPath id="clip0_1_5453">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            {{ grade.title }} 
          </button>
        </h2>

        <!-- body of the accordion item -->
        <div 
          :id="makeCollapse(grade.id)"
          class="accordion-collapse collapse"
          :class="{ show : activeGradeId === grade.id}"
          :aria-labelledby="makeHeading(grade.id)"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body pt-1 pb-2">
            <!-- list of courses -->
            <ul class="nav flex-column nav-pills">
              <li 
                v-for="course in grade.courses" 
                :key="course.id"
                class="nav-item overflow-auto"
              >
                <router-link 
                  :to="{ 
                    name: 'course', 
                    query: { subject: course.id, level: grade.id } 
                  }"
                  class="nav-link ps-2 pt-1" 
                  :class="{ active : activeCourseId === course.id}"
                >
                  <!-- <div class="icon-wrapper"></div> -->
                  {{ course.title }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'accordion-wrapper',
  props: {
    data: {
      type: Array,
      required: true
    },
    activeGradeId: {
      type: Number
    },
    activeCourseId: {
      type: Number
    }
  },
  data() {
    return {
    }
  },
  methods: {
    makeHeading(suffix) {
      return "heading-" + suffix
    },

    makeCollapse(suffix, asId=false) {
      const prefix = asId ? "#" : ""
      return prefix + "collapse-" + suffix
    },
  }
}
</script>

<style scoped>

.nav-item > a {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.accordion-button svg {
  margin-right: 8px;
  fill : #0c63e4;
}

.accordion-button.collapsed svg {
  fill: #7D7DA3;
}

.icon-wrapper {
  vertical-align: middle;
}
.icon-wrapper {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('@/assets/images/image72.svg');
  background-position: center;
}
</style>