/**
 * @fileoverview gRPC-Web generated client stub for kazatel.books
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.1
// source: taskreader/taskreader.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var taskeditor_taskeditor_pb = require('../taskeditor/taskeditor_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_type_datetime_pb = require('../google/type/datetime_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.kazatel = {};
proto.kazatel.books = require('./taskreader_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.books.TaskReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.books.TaskReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.BooksRequest,
 *   !proto.kazatel.books.Book>}
 */
const methodDescriptor_TaskReader_Books = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/Books',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.kazatel.books.BooksRequest,
  proto.kazatel.books.Book,
  /**
   * @param {!proto.kazatel.books.BooksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.Book.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.BooksRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.Book>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.books =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/Books',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Books);
};


/**
 * @param {!proto.kazatel.books.BooksRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.Book>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.books =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/Books',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Books);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.Book,
 *   !proto.kazatel.books.BookDetail>}
 */
const methodDescriptor_TaskReader_Book = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/Book',
  grpc.web.MethodType.UNARY,
  proto.kazatel.books.Book,
  proto.kazatel.books.BookDetail,
  /**
   * @param {!proto.kazatel.books.Book} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.BookDetail.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.Book} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.books.BookDetail)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.BookDetail>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.book =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/Book',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Book,
      callback);
};


/**
 * @param {!proto.kazatel.books.Book} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.books.BookDetail>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.book =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/Book',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Book);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.NodesRequest,
 *   !proto.kazatel.books.Node>}
 */
const methodDescriptor_TaskReader_BookNodes = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/BookNodes',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.kazatel.books.NodesRequest,
  proto.kazatel.books.Node,
  /**
   * @param {!proto.kazatel.books.NodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.Node.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.NodesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.Node>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.bookNodes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/BookNodes',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookNodes);
};


/**
 * @param {!proto.kazatel.books.NodesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.Node>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.bookNodes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/BookNodes',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookNodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.NodeRequest,
 *   !proto.kazatel.books.NodeDetail>}
 */
const methodDescriptor_TaskReader_Node = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/Node',
  grpc.web.MethodType.UNARY,
  proto.kazatel.books.NodeRequest,
  proto.kazatel.books.NodeDetail,
  /**
   * @param {!proto.kazatel.books.NodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.NodeDetail.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.NodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.books.NodeDetail)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.NodeDetail>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.node =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/Node',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Node,
      callback);
};


/**
 * @param {!proto.kazatel.books.NodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.books.NodeDetail>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.node =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/Node',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Node);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.books.LevelsResponse>}
 */
const methodDescriptor_TaskReader_Levels = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/Levels',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.books.LevelsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.LevelsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.LevelsResponse>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.levels =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/Levels',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Levels);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.LevelsResponse>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.levels =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/Levels',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Levels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.books.SubjectsResponse>}
 */
const methodDescriptor_TaskReader_Subjects = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/Subjects',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.books.SubjectsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.SubjectsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.SubjectsResponse>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.subjects =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/Subjects',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Subjects);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.SubjectsResponse>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.subjects =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/Subjects',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Subjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.books.NodeTypesResponse>}
 */
const methodDescriptor_TaskReader_NodeTypes = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/NodeTypes',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.books.NodeTypesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.NodeTypesResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.NodeTypesResponse>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.nodeTypes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/NodeTypes',
      request,
      metadata || {},
      methodDescriptor_TaskReader_NodeTypes);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.NodeTypesResponse>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.nodeTypes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/NodeTypes',
      request,
      metadata || {},
      methodDescriptor_TaskReader_NodeTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.books.SubjectsByLevelResponse>}
 */
const methodDescriptor_TaskReader_SubjectsByLevel = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/SubjectsByLevel',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.books.SubjectsByLevelResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.SubjectsByLevelResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.SubjectsByLevelResponse>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.subjectsByLevel =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/SubjectsByLevel',
      request,
      metadata || {},
      methodDescriptor_TaskReader_SubjectsByLevel);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.SubjectsByLevelResponse>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.subjectsByLevel =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/SubjectsByLevel',
      request,
      metadata || {},
      methodDescriptor_TaskReader_SubjectsByLevel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.books.LevelsBySubjectResponse>}
 */
const methodDescriptor_TaskReader_LevelsBySubject = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/LevelsBySubject',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.books.LevelsBySubjectResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.LevelsBySubjectResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.LevelsBySubjectResponse>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.levelsBySubject =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/LevelsBySubject',
      request,
      metadata || {},
      methodDescriptor_TaskReader_LevelsBySubject);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.LevelsBySubjectResponse>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.levelsBySubject =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/LevelsBySubject',
      request,
      metadata || {},
      methodDescriptor_TaskReader_LevelsBySubject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.tasks.TaskCardCreate,
 *   !proto.kazatel.tasks.TaskCardCreateResponse>}
 */
const methodDescriptor_TaskReader_Create = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/Create',
  grpc.web.MethodType.UNARY,
  taskeditor_taskeditor_pb.TaskCardCreate,
  taskeditor_taskeditor_pb.TaskCardCreateResponse,
  /**
   * @param {!proto.kazatel.tasks.TaskCardCreate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  taskeditor_taskeditor_pb.TaskCardCreateResponse.deserializeBinary
);


/**
 * @param {!proto.kazatel.tasks.TaskCardCreate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.tasks.TaskCardCreateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.tasks.TaskCardCreateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/Create',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Create,
      callback);
};


/**
 * @param {!proto.kazatel.tasks.TaskCardCreate} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.tasks.TaskCardCreateResponse>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/Create',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.tasks.TaskCardDelete,
 *   !proto.kazatel.tasks.TaskCard>}
 */
const methodDescriptor_TaskReader_Delete = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/Delete',
  grpc.web.MethodType.UNARY,
  taskeditor_taskeditor_pb.TaskCardDelete,
  taskeditor_taskeditor_pb.TaskCard,
  /**
   * @param {!proto.kazatel.tasks.TaskCardDelete} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  taskeditor_taskeditor_pb.TaskCard.deserializeBinary
);


/**
 * @param {!proto.kazatel.tasks.TaskCardDelete} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.tasks.TaskCard)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.tasks.TaskCard>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/Delete',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Delete,
      callback);
};


/**
 * @param {!proto.kazatel.tasks.TaskCardDelete} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.tasks.TaskCard>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/Delete',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.tasks.TaskCardCansel,
 *   !proto.kazatel.tasks.TaskCard>}
 */
const methodDescriptor_TaskReader_Cancel = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/Cancel',
  grpc.web.MethodType.UNARY,
  taskeditor_taskeditor_pb.TaskCardCansel,
  taskeditor_taskeditor_pb.TaskCard,
  /**
   * @param {!proto.kazatel.tasks.TaskCardCansel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  taskeditor_taskeditor_pb.TaskCard.deserializeBinary
);


/**
 * @param {!proto.kazatel.tasks.TaskCardCansel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.tasks.TaskCard)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.tasks.TaskCard>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.cancel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/Cancel',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Cancel,
      callback);
};


/**
 * @param {!proto.kazatel.tasks.TaskCardCansel} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.tasks.TaskCard>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.cancel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/Cancel',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Cancel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.TaskCardUpdate,
 *   !proto.kazatel.tasks.TaskCard>}
 */
const methodDescriptor_TaskReader_Update = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/Update',
  grpc.web.MethodType.UNARY,
  proto.kazatel.books.TaskCardUpdate,
  taskeditor_taskeditor_pb.TaskCard,
  /**
   * @param {!proto.kazatel.books.TaskCardUpdate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  taskeditor_taskeditor_pb.TaskCard.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.TaskCardUpdate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.tasks.TaskCard)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.tasks.TaskCard>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/Update',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Update,
      callback);
};


/**
 * @param {!proto.kazatel.books.TaskCardUpdate} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.tasks.TaskCard>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/Update',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.tasks.TaskCardList>}
 */
const methodDescriptor_TaskReader_List = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/List',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  taskeditor_taskeditor_pb.TaskCardList,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  taskeditor_taskeditor_pb.TaskCardList.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.tasks.TaskCardList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.tasks.TaskCardList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/List',
      request,
      metadata || {},
      methodDescriptor_TaskReader_List,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.tasks.TaskCardList>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/List',
      request,
      metadata || {},
      methodDescriptor_TaskReader_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.TaskCardRequest,
 *   !proto.kazatel.books.TaskCardData>}
 */
const methodDescriptor_TaskReader_Task = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/Task',
  grpc.web.MethodType.UNARY,
  proto.kazatel.books.TaskCardRequest,
  proto.kazatel.books.TaskCardData,
  /**
   * @param {!proto.kazatel.books.TaskCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.TaskCardData.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.TaskCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.books.TaskCardData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.TaskCardData>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.task =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/Task',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Task,
      callback);
};


/**
 * @param {!proto.kazatel.books.TaskCardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.books.TaskCardData>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.task =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/Task',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Task);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.books.RestTaskResponse>}
 */
const methodDescriptor_TaskReader_Rest = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/Rest',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.books.RestTaskResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.RestTaskResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.books.RestTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.RestTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.rest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/Rest',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Rest,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.books.RestTaskResponse>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.rest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/Rest',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Rest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.tasks.TaskCardSaveContentInitResponse>}
 */
const methodDescriptor_TaskReader_InitiateMultipartUpload = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/InitiateMultipartUpload',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  taskeditor_taskeditor_pb.TaskCardSaveContentInitResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  taskeditor_taskeditor_pb.TaskCardSaveContentInitResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.tasks.TaskCardSaveContentInitResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.tasks.TaskCardSaveContentInitResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.initiateMultipartUpload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/InitiateMultipartUpload',
      request,
      metadata || {},
      methodDescriptor_TaskReader_InitiateMultipartUpload,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.tasks.TaskCardSaveContentInitResponse>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.initiateMultipartUpload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/InitiateMultipartUpload',
      request,
      metadata || {},
      methodDescriptor_TaskReader_InitiateMultipartUpload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.tasks.TaskCardSaveGenerateUrlParts,
 *   !proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse>}
 */
const methodDescriptor_TaskReader_GeneratePresignedUrlsParts = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/GeneratePresignedUrlsParts',
  grpc.web.MethodType.UNARY,
  taskeditor_taskeditor_pb.TaskCardSaveGenerateUrlParts,
  taskeditor_taskeditor_pb.TaskCardSaveGenerateUrlPartsResponse,
  /**
   * @param {!proto.kazatel.tasks.TaskCardSaveGenerateUrlParts} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  taskeditor_taskeditor_pb.TaskCardSaveGenerateUrlPartsResponse.deserializeBinary
);


/**
 * @param {!proto.kazatel.tasks.TaskCardSaveGenerateUrlParts} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.generatePresignedUrlsParts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/GeneratePresignedUrlsParts',
      request,
      metadata || {},
      methodDescriptor_TaskReader_GeneratePresignedUrlsParts,
      callback);
};


/**
 * @param {!proto.kazatel.tasks.TaskCardSaveGenerateUrlParts} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.generatePresignedUrlsParts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/GeneratePresignedUrlsParts',
      request,
      metadata || {},
      methodDescriptor_TaskReader_GeneratePresignedUrlsParts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.tasks.TaskCardSaveComplete,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TaskReader_CompleteMultipartUpload = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/CompleteMultipartUpload',
  grpc.web.MethodType.UNARY,
  taskeditor_taskeditor_pb.TaskCardSaveComplete,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.tasks.TaskCardSaveComplete} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.tasks.TaskCardSaveComplete} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.completeMultipartUpload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/CompleteMultipartUpload',
      request,
      metadata || {},
      methodDescriptor_TaskReader_CompleteMultipartUpload,
      callback);
};


/**
 * @param {!proto.kazatel.tasks.TaskCardSaveComplete} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.completeMultipartUpload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/CompleteMultipartUpload',
      request,
      metadata || {},
      methodDescriptor_TaskReader_CompleteMultipartUpload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.books.TaskContent>}
 */
const methodDescriptor_TaskReader_Content = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/Content',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  proto.kazatel.books.TaskContent,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.TaskContent.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.books.TaskContent)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.TaskContent>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.content =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/Content',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Content,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.books.TaskContent>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.content =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/Content',
      request,
      metadata || {},
      methodDescriptor_TaskReader_Content);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.TaskContent,
 *   !proto.google.protobuf.StringValue>}
 */
const methodDescriptor_TaskReader_UpdateContent = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/UpdateContent',
  grpc.web.MethodType.UNARY,
  proto.kazatel.books.TaskContent,
  google_protobuf_wrappers_pb.StringValue,
  /**
   * @param {!proto.kazatel.books.TaskContent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.StringValue.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.TaskContent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.StringValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.StringValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.updateContent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/UpdateContent',
      request,
      metadata || {},
      methodDescriptor_TaskReader_UpdateContent,
      callback);
};


/**
 * @param {!proto.kazatel.books.TaskContent} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.StringValue>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.updateContent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/UpdateContent',
      request,
      metadata || {},
      methodDescriptor_TaskReader_UpdateContent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TaskReader_DeleteContent = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/DeleteContent',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.deleteContent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/DeleteContent',
      request,
      metadata || {},
      methodDescriptor_TaskReader_DeleteContent,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.deleteContent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/DeleteContent',
      request,
      metadata || {},
      methodDescriptor_TaskReader_DeleteContent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.BookDetail,
 *   !proto.google.protobuf.StringValue>}
 */
const methodDescriptor_TaskReader_AddBook = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/AddBook',
  grpc.web.MethodType.UNARY,
  proto.kazatel.books.BookDetail,
  google_protobuf_wrappers_pb.StringValue,
  /**
   * @param {!proto.kazatel.books.BookDetail} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.StringValue.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.BookDetail} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.StringValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.StringValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.addBook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/AddBook',
      request,
      metadata || {},
      methodDescriptor_TaskReader_AddBook,
      callback);
};


/**
 * @param {!proto.kazatel.books.BookDetail} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.StringValue>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.addBook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/AddBook',
      request,
      metadata || {},
      methodDescriptor_TaskReader_AddBook);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.NodeDetail,
 *   !proto.google.protobuf.StringValue>}
 */
const methodDescriptor_TaskReader_AddBookNode = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/AddBookNode',
  grpc.web.MethodType.UNARY,
  proto.kazatel.books.NodeDetail,
  google_protobuf_wrappers_pb.StringValue,
  /**
   * @param {!proto.kazatel.books.NodeDetail} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.StringValue.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.NodeDetail} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.StringValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.StringValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.addBookNode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/AddBookNode',
      request,
      metadata || {},
      methodDescriptor_TaskReader_AddBookNode,
      callback);
};


/**
 * @param {!proto.kazatel.books.NodeDetail} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.StringValue>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.addBookNode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/AddBookNode',
      request,
      metadata || {},
      methodDescriptor_TaskReader_AddBookNode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TaskReader_DeleteBookNode = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/DeleteBookNode',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.deleteBookNode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/DeleteBookNode',
      request,
      metadata || {},
      methodDescriptor_TaskReader_DeleteBookNode,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.deleteBookNode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/DeleteBookNode',
      request,
      metadata || {},
      methodDescriptor_TaskReader_DeleteBookNode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TaskReader_BookPublish = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/BookPublish',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.bookPublish =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/BookPublish',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookPublish,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.bookPublish =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/BookPublish',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookPublish);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TaskReader_BookBlocking = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/BookBlocking',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.bookBlocking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/BookBlocking',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookBlocking,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.bookBlocking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/BookBlocking',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookBlocking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.BookDetail,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TaskReader_BookEdit = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/BookEdit',
  grpc.web.MethodType.UNARY,
  proto.kazatel.books.BookDetail,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.books.BookDetail} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.BookDetail} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.bookEdit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/BookEdit',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookEdit,
      callback);
};


/**
 * @param {!proto.kazatel.books.BookDetail} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.bookEdit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/BookEdit',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookEdit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TaskReader_BookDelete = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/BookDelete',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.bookDelete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/BookDelete',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookDelete,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.bookDelete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/BookDelete',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookDelete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.books.BookType>}
 */
const methodDescriptor_TaskReader_BookTypes = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/BookTypes',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.books.BookType,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.BookType.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.BookType>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.bookTypes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/BookTypes',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookTypes);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.BookType>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.bookTypes =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/BookTypes',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.NodeDetail,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TaskReader_EditBookNode = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/EditBookNode',
  grpc.web.MethodType.UNARY,
  proto.kazatel.books.NodeDetail,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.books.NodeDetail} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.NodeDetail} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.editBookNode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/EditBookNode',
      request,
      metadata || {},
      methodDescriptor_TaskReader_EditBookNode,
      callback);
};


/**
 * @param {!proto.kazatel.books.NodeDetail} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.editBookNode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/EditBookNode',
      request,
      metadata || {},
      methodDescriptor_TaskReader_EditBookNode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.NodesOrder,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TaskReader_EditBookNodesOrder = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/EditBookNodesOrder',
  grpc.web.MethodType.UNARY,
  proto.kazatel.books.NodesOrder,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.books.NodesOrder} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.NodesOrder} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.editBookNodesOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/EditBookNodesOrder',
      request,
      metadata || {},
      methodDescriptor_TaskReader_EditBookNodesOrder,
      callback);
};


/**
 * @param {!proto.kazatel.books.NodesOrder} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.editBookNodesOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/EditBookNodesOrder',
      request,
      metadata || {},
      methodDescriptor_TaskReader_EditBookNodesOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TaskReader_BookToDraft = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/BookToDraft',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.bookToDraft =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/BookToDraft',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookToDraft,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.bookToDraft =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/BookToDraft',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookToDraft);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.Links,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TaskReader_NodeLinksAdd = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/NodeLinksAdd',
  grpc.web.MethodType.UNARY,
  proto.kazatel.books.Links,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.books.Links} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.Links} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.nodeLinksAdd =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/NodeLinksAdd',
      request,
      metadata || {},
      methodDescriptor_TaskReader_NodeLinksAdd,
      callback);
};


/**
 * @param {!proto.kazatel.books.Links} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.nodeLinksAdd =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/NodeLinksAdd',
      request,
      metadata || {},
      methodDescriptor_TaskReader_NodeLinksAdd);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.Links,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TaskReader_NodeLinksDelete = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/NodeLinksDelete',
  grpc.web.MethodType.UNARY,
  proto.kazatel.books.Links,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.books.Links} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.Links} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.nodeLinksDelete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/NodeLinksDelete',
      request,
      metadata || {},
      methodDescriptor_TaskReader_NodeLinksDelete,
      callback);
};


/**
 * @param {!proto.kazatel.books.Links} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.nodeLinksDelete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/NodeLinksDelete',
      request,
      metadata || {},
      methodDescriptor_TaskReader_NodeLinksDelete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.books.Link>}
 */
const methodDescriptor_TaskReader_NodeLinks = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/NodeLinks',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_wrappers_pb.StringValue,
  proto.kazatel.books.Link,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.Link.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.Link>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.nodeLinks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/NodeLinks',
      request,
      metadata || {},
      methodDescriptor_TaskReader_NodeLinks);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.Link>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.nodeLinks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/NodeLinks',
      request,
      metadata || {},
      methodDescriptor_TaskReader_NodeLinks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.books.LinksInherit,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TaskReader_NodeLinksInherit = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/NodeLinksInherit',
  grpc.web.MethodType.UNARY,
  proto.kazatel.books.LinksInherit,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.books.LinksInherit} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.books.LinksInherit} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.nodeLinksInherit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/NodeLinksInherit',
      request,
      metadata || {},
      methodDescriptor_TaskReader_NodeLinksInherit,
      callback);
};


/**
 * @param {!proto.kazatel.books.LinksInherit} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.nodeLinksInherit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/NodeLinksInherit',
      request,
      metadata || {},
      methodDescriptor_TaskReader_NodeLinksInherit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodDescriptor_TaskReader_NodeLinksIsInherited = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/NodeLinksIsInherited',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.BoolValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.BoolValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.nodeLinksIsInherited =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.books.TaskReader/NodeLinksIsInherited',
      request,
      metadata || {},
      methodDescriptor_TaskReader_NodeLinksIsInherited,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.BoolValue>}
 *     Promise that resolves to the response
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.nodeLinksIsInherited =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.books.TaskReader/NodeLinksIsInherited',
      request,
      metadata || {},
      methodDescriptor_TaskReader_NodeLinksIsInherited);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.books.Link>}
 */
const methodDescriptor_TaskReader_BookLinks = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/BookLinks',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_wrappers_pb.StringValue,
  proto.kazatel.books.Link,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.Link.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.Link>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.bookLinks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/BookLinks',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookLinks);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.Link>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.bookLinks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/BookLinks',
      request,
      metadata || {},
      methodDescriptor_TaskReader_BookLinks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.books.LinkedNodes>}
 */
const methodDescriptor_TaskReader_EntityLinkedTasks = new grpc.web.MethodDescriptor(
  '/kazatel.books.TaskReader/EntityLinkedTasks',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_wrappers_pb.StringValue,
  proto.kazatel.books.LinkedNodes,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.books.LinkedNodes.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.LinkedNodes>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderClient.prototype.entityLinkedTasks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/EntityLinkedTasks',
      request,
      metadata || {},
      methodDescriptor_TaskReader_EntityLinkedTasks);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.books.LinkedNodes>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.books.TaskReaderPromiseClient.prototype.entityLinkedTasks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.books.TaskReader/EntityLinkedTasks',
      request,
      metadata || {},
      methodDescriptor_TaskReader_EntityLinkedTasks);
};


module.exports = proto.kazatel.books;

