<template>
  <div class="input__wrapper">
    <label-text>{{ label }}</label-text>
    <textarea
      class="input input_type_textarea"
      :value="value"
      @input="updateValue"
      :placeholder="placeholder"
      :required="required"
      :max="max"
    />
  </div>
</template>

<script>
import LabelText from '@/components/UI/texts/labels/LabelText.vue';

export default {
  name: 'textarea-component',
  components: {
    LabelText,
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: String,
    placeholder: String,
    required: Boolean,
    max: [String, Number]
  },
  data() {},
  methods: {
    updateValue(event) {
      this.$emit('update:value', event.target.value);
    }
  }
};
</script>

<!-- <style src="./input.css"></style> -->
<style lang="scss" scoped>
@import './input.scss';

.input__wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.input_type_textarea {
  border: 2px solid var(--background-light-20);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  color: var(--text-black);
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
  min-height: 150px
}

.input_type_textarea::placeholder {
  width: 100%;
  color: var(--text-light);
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
}
</style>
