<template>
<footer>
  <card-background class="partners__background" v-if="!showParthnersBlock">
    <div class="partners__wrapper">
      <a
        v-for="item in items" :key="item"
        :href="item.link"
        target="_blank"
      >
        <img class="partner__logo" :src="require(`@/assets/images/partners/partner_id_${item.id}.png`)" :alt="item.title"/>
      </a>
    </div>
  </card-background>
  <card-background :bgColor="'var(--background-brown)'" class="footer__background">
    <div class="footer__wrapper">
      <div class="footer__logo-wrapper">
        <div class="logo__wrapper__align">
          <img
            src="@/assets/images/kazatel-logo.svg"
            class="footer__logo"
            alt="Казатель Лого"
          />
          <heading-size-h2
            :textColor="'var(--white)'"
          >
            Казатель
          </heading-size-h2>
        </div>
        <button v-if="windowWidth < 993" id="btn-open" aria-label="раскрыть подвал" class="button-open" @click="toggleFooter">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              v-if="isFooterOpened"
              d="M27.9004 22.9502L18.0004 13.0502L8.10039 22.9502"
              stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
            />
            <path
              v-else
              d="M8.09961 13.0498L17.9996 22.9498L27.8996 13.0498"
              stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
            />
          </svg>
        </button>

      </div>

      <div class="footer__content-wrapper" v-if="windowWidth > 993">
        <footer-content-section section-title="Сотрудничество" :items="cooperation"/>
        <footer-content-section section-title="Документы" :items="documents"/>
        <footer-content-section section-title="Наши партнеры" :items="items"/>
      </div>

      <div class="footer__content-wrapper footer__content-wrapper_type_row" v-if="windowWidth > 993">
        <footer-content-section list-class="content-section__list_type_row" section-title="" :items="commonLinks"/>
        <p class="label label_size_M">Kazatel version {{ version }}</p>
      </div>

      <div class="footer__content-wrapper" v-if="isFooterOpened && windowWidth < 993">
        <footer-content-section section-title="Сотрудничество" :items="cooperation"/>
        <footer-content-section section-title="Документы" :items="documents"/>
        <footer-content-section section-title="Наши партнеры" :items="items"/>
      </div>

      <div class="footer__content-wrapper footer__content-wrapper_type_row" v-if="isFooterOpened && windowWidth < 993">
        <footer-content-section list-class="content-section__list_type_row" section-title="" :items="commonLinks"/>
        <p class="label label_size_M">Kazatel version {{ version }}</p>
      </div>
    </div>
  </card-background>
</footer>
</template>

<script>
import CardBackground from '@/components/UI/card/CardBackground.vue';
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue';
import FooterContentSection from './components/FooterContentSection.vue';

import partners from './partners.js'


export default {
  name: "footer-component",

  components: {
    CardBackground,
    HeadingSizeH2,
    FooterContentSection
  },

  data(){
    return{
      windowWidth: window.innerWidth,
      isFooterOpened: false,
      items: partners,
      version: process.env.VUE_APP_VERSION,
      showParthners: false,
    }
  },

  props: {
    cooperation: {
      type: Array,
      required: true
    },
    documents: {
      type: Array,
      required: true
    },
    commonLinks: {
      type: Array,
      required: true
    }
  },

  methods:{
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    toggleFooter() {
      this.isFooterOpened = !this.isFooterOpened;
    },
  },

  computed:{
    showParthnersBlock(){
      return this.$route.meta.layout === 'moderation'
    }
  },

  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
    console.log()
  },

}
</script>

<style scoped>
footer{
  display: flex;
  flex-direction: column;
}
.partners__background{
  border-radius: 8px 8px 0px 0px;
}

.partners__wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.partner__logo{
  filter: saturate(0);
  transition: .3s ease-in-out;
}
.partner__logo:hover{
  filter: saturate(1);
  transition: .3s ease-in-out;
}
.footer__background{
  border-radius: 0px 0px 8px 8px;
}
.footer__wrapper{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.footer__logo-wrapper{
  display: flex;
  justify-content: space-between;
}
.logo__wrapper__align{
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}
.button-open{
  background-color: transparent;
  border: none;
  outline: none;
}
.footer__logo{
  width: 32px;
  height: 32px;
}

.footer__content-wrapper{
  display: flex;
  gap: 100px;
}

.label{
  color: var(--kaz-base-base-04);
}

.footer__content-wrapper_type_row{
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  footer{
    flex-direction: column-reverse;
  }
  .partners__background{
    border-radius: 0px 0px 8px 8px;
  }

  .partners__wrapper{
    flex-direction: column-reverse;
    gap: 16px;
  }
  .footer__background{
    border-radius: 8px 8px 0px 0px;
  }
  .footer__content-wrapper_type_row{
    align-items: flex-start;
    gap: 16px;
  }
}

@media screen and (max-width:768px) {
  .footer__content-wrapper{
    flex-direction: column;
    gap: 24px;
  }
}
</style>
