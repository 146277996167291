export default [
  {
    id: 1,
    title: 'Российская Электронная Школа',
    link: 'https://resh.edu.ru/'
  },
  {
    id: 2,
    title: 'Министерство Просвещения РФ',
    link: 'https://edu.gov.ru/'
  },
  {
    id: 3,
    title: 'АО «Просвещение»',
    link: 'https://prosv.ru/'
  },

]