<template>
  <kaz-dialog
    :size="'M'"
    :visible="isVisible"
    @close="$emit('close')"
  >
    <div class="features">
      <img src="@/assets/KazIllustrations/promo/promoBox.png" class="features__image"/>
      <Badge
        :label="'Коробка подарков'"
        :appearance="'accent'"
        :icon="true"
        :iconName="'kuiIconStar'"
      />
      <div class="features__text">
        <h2 class="heading heading_size_h2">Войди, чтобы увидеть это решение</h2>
        <p class="label label_size_M">Это быстро и абслютно бесплатно. После входа или регистрации ты получишь 3 подарка:</p>
      </div>

      <div class="features__wrapper">
        <div class="feature">
          <svg-icon :iconName="'kuiIconLockOpenLarge'" :size="'24px'" />
          <div class="feature__text">
            <h4 class="heading heading_size_h4">Увеличенные лимиты</h4>
            <p class="body body_size_XS">Больше просмотров решений и больше вопросов к Казатель GPT</p>
          </div>
        </div>
        <div class="feature">
          <svg-icon :iconName="'kuiIconEditLarge'" :size="'24px'" />
          <div class="feature__text">
            <h4 class="heading heading_size_h4">Твои собственные решения</h4>
            <p class="body body_size_XS">Заноси, публикуй и зарабатывай на своих решениях</p>
          </div>
        </div>
        <div class="feature">
          <svg-icon :iconName="'kuiIconHeartLarge'" :size="'24px'" />
          <div class="feature__text">
            <h4 class="heading heading_size_h4">Избранное в рюкзаке</h4>
            <p class="body body_size_XS">Сохраняй нужные тебе учебники в одном месте, никакого беспорядка</p>
          </div>
        </div>
      </div>

      <div class="features__buttons">
        <kaz-button
          :label="'Регистрация'"
          :appearance="'secondary'"
          :size="'L'"
          :mode="'normal'"
          @click="sendToReg()"
        />
        <kaz-button
          :label="'Вход в Казатель'"
          :appearance="'primary'"
          :size="'L'"
          @click="$router.push({name: 'login'})"
        />
      </div>
    </div>
  </kaz-dialog>
</template>

<script>
import PopupWindow from '@/components/UI/popups/PopupWindow.vue';
import ParagraphSizeMedium from '@/components/UI/texts/paragraphs/ParagraphSizeMedium.vue';
import ParagraphSizeSmall from '@/components/UI/texts/paragraphs/ParagraphSizeSmall.vue';
import ButtonPrimary from '@/components/UI/buttons/ButtonPrimary.vue';

import KazDialog from '@/components/KazUI/slots/dialog'
import Badge from '@/components/KazUI/atoms/badge'
import KazButton from '@/components/KazUI/atoms/button'


export default {
  name: 'popup-account-login',

  components: {
    PopupWindow,
    ParagraphSizeMedium,
    ParagraphSizeSmall,
    ButtonPrimary,
    KazDialog,
    Badge,
    KazButton
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },

  methods:{
    sendToReg(){
      this.$setMetrikaGoal('POPUP_REG');
      this.$router.push('/register')
    }
  },

  emits: ['close']
}
</script>

<style src="@/components/UI/buttons/button.css"></style>
<style scoped>
.features{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.label_size_M{
  text-align: center;
}
.features__image{
  width: 100%;
}
.features__text{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;
}
.features__wrapper{
  background-color: var(--kaz-base-secondary);
  padding: 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 32px;
}
.features__buttons{
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.feature{
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.feature svg{
  --kaz-base-base-09: var(--kaz-base-primary);
}
.feature__text{
  display: block;
}
.body_size_XS{
  color: var(--kaz-textIcons-text-02)
}
</style>
