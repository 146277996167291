import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Spinner from '@/components/UI/Spinner';
import SectionTitle from '@/components/common/SectionTitle';
import Breadcrumb from '@/components/UI/Breadcrumb';
import MessageError from '@/components/UI/MessageError';
import authPlugin from '@/plugins/authPlugin.js';
import directives from '@/directives';
import CookieNotify from '@/components/UI/CookieNotify';
import SvgIcon from '@/components/SvgIcon';
import metrikaPlugin from '@/plugins/metrikaPlugin'


const app = createApp(App);

if (process.env.NODE_ENV === 'development') {
  app.config.performance = true;
  console.log('performance mode has been setup');
}

// console.warn('Enable analytics flag:', process.env.VUE_APP_ENABLE_ANALYTICS);

app.use(store);
app.use(router);
app.use(authPlugin);
app.use(metrikaPlugin);

app.component('spinner', Spinner);
app.component('section-title', SectionTitle);
// app.component('search-form', SearchForm);
app.component('breadcrumb', Breadcrumb);
app.component('message-error', MessageError);
app.component('cookie-notify', CookieNotify);
app.component('svg-icon', SvgIcon);

directives.forEach(directive => {
  app.directive(directive.name, directive);
})

app.mount('#app');
