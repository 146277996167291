function createTree(nodesList, nodesTypes = null) {
  console.log('CREATING TREE:', nodesList);
  
  let rootNode;
  for (const key in nodesList) {
    const node = nodesList[key];
    if (node.parentId) {
      // console.log(`pId: ${node.parentId}`);
      // console.log(`pId IN: ${(node.parentId in nodesList)}`);
      nodesList[node.parentId].children.push(node);
    } else {
      rootNode = node;
    }
  }

  // Move leaf nodes from children array to content one of its parent
  for (const key in nodesList) {
    let node = nodesList[key];
    if (node.parentId) {
      if (!node.children.length && !node.content.length) {
        const children = nodesList[node.parentId].children.filter(i => i.id !== node.id);
        nodesList[node.parentId].children = children;
        nodesList[node.parentId].content.push(node);
      }
    }
  }

  for (const key in nodesList) {
    let node = nodesList[key];
    // Sort children
    let firstChild = null;
    const mapChildren = new Map;
    for (const keyChild in node.children) {
      const child = node.children[keyChild]
      mapChildren[child.id] = child;
      if (child.prevId == '') {
        firstChild = child;
      }
    }

    const children = [];
    while (firstChild) {
      children.push(mapChildren[firstChild.id]);
      firstChild = firstChild.nextId ? mapChildren[firstChild.nextId] : null;
    }
    if (node.children.length == children.length) {
      // Filter children nodes by non-zero type id (previous books have zero type id)
      // Children array must contain only allowed types - when field children = true
      if (nodesTypes && node.typeId !== 0) {
        node.children = [
          ...children.filter(node => nodesTypes[node.typeId].children )
        ];
      } else {
        node.children = [ ...children ];
      }
    }

    // Sort content
    let firstContent = null;
    const mapContent = new Map;
    for (const keyContent in node.content) {
      const child = node.content[keyContent]
      mapContent[child.id] = child;
      if (child.prevId == '') {
        firstContent = child;
      }
    }

    const content = [];
    while (firstContent) {
      content.push(mapContent[firstContent.id]);
      firstContent = firstContent.nextId ? mapContent[firstContent.nextId] : null;
    }
    if (node.content.length == content.length) {
      // Filter content nodes
      // These nodes must have field children = false which is interpreted as tasks 
      if (nodesTypes && node.typeId !== 0) {
        node.content = [
          ...content.filter(node => !nodesTypes[node.typeId].children )
        ];
      } else {
        node.content = [...content];
      }
    }
  }

  // console.log('SRC BOOK TREE:', rootNode);

  // Remove nodes those are not tasks and have no children KAZ-2182
  for (const id in nodesList) {
    const node = nodesList[id];
    if (!node.children.length && !node.content.length && nodesTypes[node.typeId].children) {
      const pId = node.parentId;
      if (pId in nodesList) {
        nodesList[pId].children = [...nodesList[pId].children?.filter(n => n.id !== id)];
        nodesList[pId].content = [...nodesList[pId].content?.filter(n => n.id !== id)];
      }
      delete nodesList[id];
    }
  }

  // console.log('BOOK TREE:', rootNode);
  
  return rootNode;
}

function showNodeContent(error) {
  const { code = null } = error || {};
  let show = true;
  switch (code) {
    case 7:
    case 13:
      show = false;
      break;
  }
  return show
}

export {
  createTree,
  showNodeContent,
}