import { TaskReaderClient } from '@/generated/taskreader/taskreader_grpc_web_pb.js';
// import { extractTokenInfo } from '@/utils/auth';
import { fetchStreamData, fetchMethodData } from '@/utils/loaders.js';
import { createMetadataWithToken } from '@/services/utils';


const taskReader = new TaskReaderClient(
  process.env.VUE_APP_REGISTRATION_SERVICE_URL, 
  null, 
  null
);

// function createMetadataWithToken() {
//   var tokens = extractTokenInfo();
//   var metadata = { 'token': tokens.accessToken.token };
//   return metadata;
// }

async function fetchBook(bookId) {
  try {
    const request = new proto.kazatel.books.Book();
    request.setId(bookId);
    const metadata = createMetadataWithToken();
    return await fetchMethodData(taskReader, 'book', { request, metadata });
  } catch (error) {
    throw error;
  }
}

async function fetchBooks({
  statusList = [],
  searchValue = '',
  pageSize = 10,
}) {
  try {
    const request = new proto.kazatel.books.BooksRequest();
    if (statusList.length) {
      request.setStatusList(statusList);
    }
    if (searchValue) {
      request.setLike(searchValue);
    }
    request.setPageSize(pageSize);

    const metadata = createMetadataWithToken();
    const stream = taskReader.books(request, metadata);
    return await fetchStreamData(stream);
  } catch (error) {
    throw error;
  }
}

async function fetchNode(nodeId, content = false) {
  /**
   * Get detailed info about book node.
   */
  try {
    const request = new proto.kazatel.books.NodeRequest();
    request.setId(nodeId);
    request.setTasksContent(content);
    const metadata = createMetadataWithToken();
    return await fetchMethodData(taskReader, 'node', { request, metadata });
  } catch (error) {
    throw error;
  }
}

async function fetchNodes(bookId, parentId) {
  /**
   * Get node list of the book.
   */
  try {
    const request = new proto.kazatel.books.NodesRequest();
    if (bookId === undefined && parentId === undefined) {
      throw new Error('Parameters bookId and parentId are undefined both.');
    }
    if (bookId) {
      request.setBookId(bookId);
    }
    if (parentId) {
      request.setParentId(parentId);
    }
    const metadata = createMetadataWithToken();
    const stream = taskReader.bookNodes(request, metadata);
    return await fetchStreamData(stream);
  } catch (error) {
    throw error;
  }
}

async function fetchContent(contentId) {
  try {
    const request = new proto.google.protobuf.StringValue();
    request.setValue(contentId);
    const metadata = createMetadataWithToken();
    return await fetchMethodData(taskReader, 'content', { request, metadata });
  } catch (error) {
    throw error;
  }
}

async function fetchTask(taskId) {
  try {
    const request = new proto.kazatel.tasks.CardRequest();
    request.setTaskId(taskId);
    const metadata = createMetadataWithToken();
    return await fetchMethodData(taskReader, 'task', { request, metadata }); 
  } catch (error) {
    throw error;
  }
}

async function saveBook(bookInfo) {
  try {
    console.log(bookInfo);
    const request = new proto.kazatel.books.BookDetail();
    const book = new proto.kazatel.books.Book();
    book.setId(bookInfo.id);
    book.setNameOfficial(bookInfo.title);
    book.setName(bookInfo.titleSeo);
    book.setPublisher(bookInfo.publisher);
    book.setTypeId(bookInfo.bookType[0].value);
    book.setSubjectId(bookInfo.subject[0].value);
    book.setAuthors(bookInfo.authors.map(author => author.title).join(', '));
    book.setViewUrl(bookInfo.cover[0]);
    book.setPublishYear(bookInfo.publishYears[0].title);
    book.setPart(bookInfo.parts[0].title);
    book.setLevelsList(bookInfo.levels.map(level => level.value));
    book.setRank(bookInfo.displayPriority);
    request.setBook(book);
    request.setPages(bookInfo.pages);
    book.setFgos(bookInfo.fgos);

    const metadata = createMetadataWithToken();
    if(bookInfo.id) {
      await fetchMethodData(taskReader, 'bookEdit', { request, metadata });
      const res = new proto.google.protobuf.StringValue();
      res.setValue(bookInfo.id);
      return res;
    }
    else
      return await fetchMethodData(taskReader, 'addBook', { request, metadata });
  } catch (error) {
    throw error;
  }
}

async function saveBookNode(nodeInfo) {
  try {
    // console.log('Save book node:', nodeInfo);
    const request = new proto.kazatel.books.NodeDetail();
    const node = new proto.kazatel.books.Node();
    node.setParentId(nodeInfo.parentId);
    node.setTitle(nodeInfo.title);
    node.setTypeId(nodeInfo.typeId[0].value);
    node.setOrder(`${nodeInfo.order}`);
    node.setPage(+nodeInfo.page);
    request.setNode(node);
    request.setBookId(nodeInfo.bookId);

    const metadata = createMetadataWithToken();
    return await fetchMethodData(taskReader, 'addBookNode', { request, metadata });
  } catch (error) {
    throw error;
  }
}

async function deleteBookNode(nodeId) {
  try {
    console.log(nodeId);
    const request = new proto.google.protobuf.StringValue();
    request.setValue(nodeId);

    const metadata = createMetadataWithToken();
    return await fetchMethodData(taskReader, 'deleteBookNode', { request, metadata });
  } catch (error) {
    throw error;
  }
}

async function fetchBookTypes() {
  /**
   * Get list of the book types.
   */
  try {
    const request = new proto.google.protobuf.Empty();
    const metadata = createMetadataWithToken();
    const stream = taskReader.bookTypes(request, metadata);
    return await fetchStreamData(stream);
  } catch (error) {
    throw error;
  }
}

async function editBookNode({ bookId, node }) {
  try {
    console.log('Updating node:', node);
    const request = new proto.kazatel.books.NodeDetail();
    const requestNode = new proto.kazatel.books.Node();
    requestNode.setId(node.id);
    requestNode.setParentId(node.parentId);
    requestNode.setTitle(node.nodeName);
    requestNode.setTypeId(node.nodeType[0].value);
    requestNode.setOrder(`${node.order}`);
    requestNode.setPage(+node.nodePage);
    request.setNode(requestNode);
    request.setBookId(bookId);
    const metadata = createMetadataWithToken();
    return await fetchMethodData(taskReader, 'editBookNode', { request, metadata });
  } catch (error) {
    throw error;
  }
}

async function fetchNodeTypes() {
  try {
    const request = new proto.google.protobuf.Empty();
    const metadata = createMetadataWithToken();
    const stream = taskReader.nodeTypes(request, metadata);
    return await fetchStreamData(stream);
  } catch (error) {
    throw error;
  }
}

async function deleteBook(id) {
  try {
    const request = new proto.google.protobuf.StringValue();
    request.setValue(id);
    const metadata = createMetadataWithToken();
    return await fetchMethodData(taskReader, 'bookDelete', { request, metadata });
  } catch (error) {
    throw error;
  }
}

async function publishBook(id) {
  try {
    const request = new proto.google.protobuf.StringValue();
    request.setValue(id);
    const metadata = createMetadataWithToken();
    return await fetchMethodData(taskReader, 'bookPublish', { request, metadata });
  } catch (error) {
    throw error;
  }
}

async function blockBook(id) {
  try {
    const request = new proto.google.protobuf.StringValue();
    request.setValue(id);
    const metadata = createMetadataWithToken();
    await fetchMethodData(taskReader, 'bookBlocking', { request, metadata });
  } catch (error) {
    throw error;
  }
}

async function draftBook(id) {
  try {
    const request = new proto.google.protobuf.StringValue();
    request.setValue(id);
    const metadata = createMetadataWithToken();
    await fetchMethodData(taskReader, 'bookToDraft', { request, metadata });
  } catch (error) {
    throw error;
  }
}

async function fetchLessonsInheritanceAttr(nodeId) {
  try {
    const request = new proto.google.protobuf.StringValue();
    request.setValue(nodeId);
    const metadata = createMetadataWithToken();
    return fetchMethodData(taskReader, 'nodeLinksIsInherited', { request, metadata });
  } catch (error) {
    throw error;
  }
}


export {
  fetchBook,
  fetchBooks,
  fetchNode,
  fetchNodes,
  fetchContent,
  fetchTask,
  saveBook,
  saveBookNode,
  fetchBookTypes,
  deleteBookNode,
  editBookNode,
  fetchNodeTypes,
  deleteBook,
  publishBook,
  blockBook,
  draftBook,
  fetchLessonsInheritanceAttr,
}