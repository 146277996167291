<template>
  <header class="header">
    <card-background class="header__wrapper" :class="{'header__wrapper_padding': isModerationRoute}">
      <div class="header__line">
        <router-link class="link" :to="{name: 'tasks'}">
          <div class="logo__wrapper">
            <img src="@/assets/brand/kazatel__logo_header.png" class="logo" alt="Казатель"/>
            <h3 class="heading heading_size_h3">Казатель</h3>
          </div>
        </router-link>

        <Button
          v-if="!sessionActive"
          :label="'Войти в Казатель'"
          :size="'M'"
          :appearance="'outline'"
          :mode="'normal'"
          :rightIcon="true"
          :rightIconName="'kuiIconLoginLarge'"
          @click="goToLogin"
          class="login__button"
        />

        <div class="header__user-wrapper" v-if="sessionActive">
          <avatar
            class="avatar"
            :class="{'avatar_type_active': isPersonalInfoRoute}"
            :size="'S'"
            :avatarImage="getSrcAvatar"
            @click="goToSettings"
          />
          <Button
            class="menu"
            :label="''"
            :size="'M'"
            :appearance="'flat'"
            :mode="'onLight'"
            :rightIcon="true"
            :rightIconName="burger ? 'kuiIconCloseLarge'  : 'kuiIconMenuLarge'"
            @click="toggleMenu"
          />
          <KazDropdown positionX="right">
            <template v-slot:button>
              <Button
                :label="''"
                :size="'M'"
                :appearance="'flat'"
                :mode="'onLight'"
                :rightIcon="true"
                :rightIconName="'kuiIconChevronDownLarge'"
              />
            </template>
            <template v-slot:menu>
              <li
                v-for="(item, index) in dropdownItems"
                class="dropdown__item dropdown__item_type_content dropdown__item_size_M"
                :key="index" 
                @click="item.action"
              >
                {{ item.title }}
              </li>
            </template>
          </KazDropdown>
        </div>

        <div class="header__line_mobile" v-if="!sessionActive">
          <Button
            v-if="!sessionActive"
            :label="''"
            :size="'M'"
            :appearance="'outline'"
            :mode="'normal'"
            :rightIcon="true"
            :rightIconName="'kuiIconLoginLarge'"
            @click="goToLogin"
          />
          <Button
            class="menu"
            :label="''"
            :size="'M'"
            :appearance="'flat'"
            :mode="'onLight'"
            :rightIcon="true"
            :rightIconName="burger ? 'kuiIconCloseLarge'  : 'kuiIconMenuLarge'"
            @click="toggleMenu"
          />
        </div>
      </div>

      <hr class="dropdown__item_type_divider" v-if="!isModerationRoute"/>

      <div class="header__line" v-if="!isModerationRoute">
        <nav class="header__navigation">
          <router-link
            :to="{ name: item.link.included[0]}"
            v-for="(item, index) in filteredSubHeaderArray" :key="index"
            :class="{ 'navigation__item_active': isLinkActive(item.link) }"
          >
            <div class="navigation__item" :class="{ 'navigation__item_badge': item.badge.name }">
              <p class="label label_size_M" v-if="!item.badge.name">{{ item.title }}</p>
              <kaz-badge
                v-if="item.badge.name"
                :label="item.title"
                :appearance="'accent'"
              />
            </div>
          </router-link>
        </nav>
      </div>
      <div class="menu__wrapper" v-show="burger">
        <nav class="mobile__navigation">
          <router-link
            :to="{ name: item.link.included[0]}"
            v-for="(item, index) in filteredSubHeaderArray" :key="index"
            :class="{ 'navigation__item_active': isLinkActive(item.link) }"
          >
            <div class="navigation__item" :class="{ 'navigation__item_badge': item.badge.name }">
              <p class="label label_size_M" v-if="!item.badge.name">{{ item.title }}</p>
              <kaz-badge
                v-if="item.badge.name"
                :label="item.title"
                :appearance="'accent'"
                :size="'M'"
              />
            </div>
          </router-link>
        </nav>
      </div>
    </card-background>
  </header>
</template>

<script>
import CardBackground from '@/components/UI/card/CardBackground.vue';

import Button from '@/components/KazUI/atoms/button'
import Avatar from '@/components/KazUI/atoms/avatar'
import Tabs from '@/components/KazUI/atoms/tabs'
import KazDropdown from '@/components/KazUI/slots/dropdown'
import TabsNavigation from '@/components/KazUI/atoms/tabsNavigation'
import KazBadge from '@/components/KazUI/atoms/badge'

import subHeaderArray from '../SubHeaderComponent/components/utils';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'header-common',

  components: {
    CardBackground,
    Button,
    Avatar,
    Tabs,
    KazDropdown,
    TabsNavigation,
    KazBadge
  },

  data() {
    return {
      subHeaderArray,
      dropdownItems:[
        {
          title: 'Настройки',
          action: this.goToSettings
        },
        {
          title: 'Выход',
          action: this.logout
        }
      ],
      burger: false,
    };
  },

  methods:{
    ...mapActions({
      createTokenState: 'authData/createState',
      openLoginPopup: 'popupAuth/openLoginPopup'
    }),


    openPopupRegistration() {
      this.openLoginPopup();
    },

    goToSettings(){
      this.$router.push('/profile')
    },

    goToLogin(){
      this.$router.push('/login')
    },

    changeTab(tabIndex) {
      this.$router.push(this.navItems[tabIndex].link);
    },

    toggleMenu(){
      this.burger = !this.burger
    },

    isLinkActive(link) {
      const hasIncluded = this.$route.matched.some(route => link?.included?.includes(route.name));
      const hasExcluded = link?.excluded?.includes(this.$route.name);
      return hasIncluded && !hasExcluded
    },

    async logout() {
      try {
        const registrationService = new RegistrationClient(this.serviceURL, null, null);
        const requestLogout = new proto.google.protobuf.Empty();
        const tokens = this.$getTokenInfo();
        const metadata = { 'token': tokens.accessToken.token };
        registrationService.logout(requestLogout, metadata, this.resultHandlerLogout);
      } catch (e) {
        // setAccessTokens({ token: "", lifetime: 0 }, { token: "", lifetime: 0 });
        // localStorage.tokenTimeCreated = 0;
        await this.createTokenState({
          accessToken: null,
          refreshToken: null,
          tokenTimeCreated: null,
          isRegistered: false
        });
        console.error('sendMail:', e, 'Ошибка выхода из сеанса пользователя');
      }
      this.$router.push('/login');
    },
    async resultHandlerLogout(err) {
      if (err) {
        let errMsg = null;
        let isAlert = false;
        switch (err.code) {
          case 2:
            errMsg = 'Сервис недоступен\n' + err.message;
            isAlert = true;
            break;
          case 14:
            errMsg = 'Сервис регистрации пользователей недоступен\n' + err.message;
            isAlert = true;
            break;
          default:
            errMsg = `Code: ${err.code}\n message: ${err.message}`;
          //isAlert = true;
        }
        console.error(errMsg);
        if (isAlert) {
          window.alert(errMsg);
        }
      } else {
        // setAccessTokens({ token: "", lifetime: 0 }, { token: "", lifetime: 0 });
        // localStorage.tokenTimeCreated = 0;
        await this.createTokenState({
          accessToken: null,
          refreshToken: null,
          tokenTimeCreated: null,
          isRegistered: false
        });
        this.$router.push("/login");
      }
    },
  },
  computed: {
    ...mapGetters({
      getStudent: 'userData/getStudent',
      getSrcAvatar: 'userData/getSrcAvatar',
      sessionActive: 'authData/sessionActive',
    }),
    isPersonalInfoRoute() {
      return this.$route.path === '/profile';
    },
    isModerationRoute() {
      return this.$route.name.includes('moderation') || this.$route.path.includes('generation');
    },
    filteredSubHeaderArray() {
      return subHeaderArray.filter((item) => {
        if (this.sessionActive) {
          return item.authDisplay;
        } else {
          return item.noAuthDisplay;
        }
      });
    },
  }
}
</script>

<style scoped>
.header__wrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 32px 0px 32px;
}
.header__wrapper_padding{
  padding: 16px 32px;
}
.header__line{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header__navigation{
  display: flex;
  flex-direction: row;
  gap: 24px;
  border-bottom: 1px solid var(--kaz-base-base-04)
}
.navigation__item{
  margin-top: 16px;
  padding-bottom: 16px;
}
.navigation__item_badge{
  margin-top: 12px;
  padding-bottom: 12px;
}
.navigation__item p{
  color: var(--kaz-textIcons-text-02);
  transition: var(--kaz-transition-molecules);
}

.navigation__item_active{
  border-bottom: 2px solid var(--kaz-base-primary);
}
.navigation__item_active p{
  color: var(--kaz-textIcons-text-01);
  transition: var(--kaz-transition-molecules);
}
.navigation__item:hover p{
  color: var(--kaz-textIcons-text-01);
  transition: var(--kaz-transition-molecules);
}
.label, a{
  text-decoration: none;
}
.mobile__navigation{
  display: none;
}

.link{
  text-decoration: none;
}
.logo__wrapper{
  display: flex;
  align-items: center;
  gap: 8px;
}
.logo{
  width: 32px;
  height: 32px;
}
.header__user-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.avatar{
  cursor: pointer
}
.menu{
  display: none;
}
.header__line_mobile{
  display: none;
}
.login__button{
  display: block;
}
@media screen and (max-width: 768px) {

  .dropdown__item_type_divider{
    display: none;
  }
  .header__line:last-of-type{
    display: none;
  }
  .header__wrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
  }
  .header__user-wrapper{
    gap: 16px;
  }
  .menu{
    display: block;
  }
  .menu__wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .header__navigation{
    display: none;
  }
  .header__line{
    gap: 16px;
  }
  .header__line_mobile{
    display: flex;
    gap: 16px;
  }
  .login__button{
    display: none;
  }

  .mobile__navigation{
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow-x: auto;
    padding-bottom: 8px;
  }
  .navigation__item{
    margin: 0;
    padding: 8px;
  }
  .navigation__item p{
    color: var(--kaz-textIcons-text-02);
  }
  .navigation__item_active{
    border-radius: 4px;
    border: none;
    background-color: var(--kaz-textIcons-link-bg);
  }
  .navigation__item_active p{
    color: var(--kaz-textIcons-link);
  }
  .button__exit{
    width: fit-content;
  }
  .navigation__item_badge{
    margin: 0;
  }
}

/* @media screen and (max-width: 568px) {
  .mobile__navigation{
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow-x: auto;
    padding-bottom: 8px;
  }

} */
</style>
