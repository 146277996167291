<template>
  <nav class="tabs">
    <router-link
      v-for="(tab, index) in tabs"
      :key="tab.id"
      @click.native="changeTab({ id: tab.id, index })"
      :ref="'tab' + index"
      :class="[ 'tab', `tab_size_${size}`, { 'tab_type_active' : currentTab.index === index }, {'tab_type_disabled' : tab.disabled } ]"
      :to="tab.link"
    >
      <svg-icon class="tab__icon" :iconName="tab.iconName" :size="'24px'" v-if="tab.iconName" />
      <span class="tab__content">
        <p v-if="size === 'M'" class="label label_size_M">{{ capitalize(tab.title) }}</p>
        <p v-if="size === 'S'" class="label label_size_S">{{ capitalize(tab.title) }}</p>
      </span>
    </router-link>
  </nav>
</template>

<script>
export default {
  name: 'tabs-navigation',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    size:{
      type: String,
      default: 'M'
    }
  },
  data() {
    return {
      currentTab: { id: null, index: 0 },
    };
  },
  mounted() {
    const index = this.tabs.findIndex(tab => this.$route.path.includes(tab.link));
    const defaultIndex = index !== -1 ? index : 0;
    this.currentTab = { id: this.tabs[defaultIndex].id, index: defaultIndex };
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      this.$emit('input', tab.id);
    },
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  },
};
</script>

<style scoped>
.tabs {
  position: relative;
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.tab{
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  text-decoration: none;
  transition: var(--kaz-transition-molecules);
}
.tab .tab__icon{
  --kaz-base-base-09: var(--kaz-textIcons-text-03);
}
.tab:hover {
  transition: var(--kaz-transition-molecules);
  background-color: var(--kaz-base-secondary);
}

.tab p {
  transition: var(--kaz-transition-molecules);
  color: var(--kaz-textIcons-text-02);
  transition: var(--kaz-transition-molecules);
}
.tab:hover p {
  transition: var(--kaz-transition-molecules);
  color: var(--kaz-textIcons-text-01);
}

.tab_size_M {
  padding: 10px 12px;
}
.tab_size_S {
  padding: 12px 0px;
}

.tab_size_M:not(:first-child) {
  margin-left: 24px;
}
.tab_size_S:not(:first-child) {
  margin-left: 16px;
}

.tab_type_active{
  background-color: var(--kaz-base-secondary);
  transition: var(--kaz-transition-molecules);
}
.tab_type_active:hover{
  background-color: var(--kaz-base-secondary-hover);
  transition: var(--kaz-transition-molecules);
}

.tab_type_active p{
  color: var(--kaz-textIcons-text-01);
  transition: var(--kaz-transition-molecules);
}

.tab_type_active .tab__icon{
  --kaz-base-base-09: var(--kaz-textIcons-text-01);
  transition: var(--kaz-transition-molecules);
}

.tab_type_disabled{
  opacity: .56;
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .tabs {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
  .tab_size_M:not(:first-child) {
    margin-left: 0px;
  }
  .tab_size_S:not(:first-child) {
    margin-left: 0px;
  }

}
</style>
