<template>
  <div class="divide-wrapper">

    <header-common/>

    <!-- <header-component/> -->
    <!-- <sub-header-component/> -->

    <div class="container__wrapper">
      <!-- <aside v-if="showAccordion" class="container__aside">
        <accordion-sidebar :data="getGrades"/>
      </aside> -->

      <favorites-component
        v-if="showFavorites && sessionActive" 
        :userHaveBooks="userHaveBooks"
      />

      <editor-intro v-if="showEditorIntro"/>
      <subject-class-filter v-if="showSubjectClassFilter" :data="getGrades"/>

      <main class="container__main" ref="mainContent">
        <router-view/>
      </main>
    </div>

    <footer-component
      v-if="showFooter"
      :cooperation="cooperation"
      :documents="documents"
      :commonLinks="commonLinks"
    />

    <!-- <cookie-notify/> -->
</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import SubjectClassFilter from '@/components/common/SubjectClassFilter.vue';
import HeaderComponent from '@/components/common/HeaderComponent';
import FooterComponent from '@/components/common/FooterComponent';
import FavoritesComponent from '@/components/common/FavoritesComponent.vue';
import SubHeaderComponent from '@/components/common/SubHeaderComponent';
import EditorIntro from '@/views/tasks/components/EditorIntro.vue';
import CookieNotify from '@/components/UI/CookieNotify.vue';

import HeaderCommon from '@/components/common/Header';


export default {
  name: 'main-layout',

  components: {
    HeaderComponent,
    FooterComponent,
    SubjectClassFilter,
    FavoritesComponent,
    SubHeaderComponent,
    EditorIntro,
    CookieNotify,
    HeaderCommon,
},

  data() {
    return {
      books: [],
      clsSubjects: [],
      listSubjectsLevel: [],
      grades:[],
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      windowWidth: window.innerWidth,
      userHaveBooks: true,
      // for FooterComponent
      documents: [
        {
          title: "Пользовательское соглашение",
          link: "/user-agreement.pdf"
        },
        {
          title: "Обработка персональных данных",
          link: "/personal-data.pdf"
        },
        {
          title: "Договор-оферта",
          link: "/oferta.pdf"
        },
        {
          title: "Оферта агентского договора",
          link: "/agent-agreement.pdf"
        }
      ],
      commonLinks: [
      {
          title: "Партнёрам",
          link: "/cooperation/partners"
        },
        {
          title: "Издательствам",
          link: "/cooperation/publishers"
        },
        {
          title: "Правообладателям",
          link: "/cooperation/rightholders"
        },
        {
          title: "Связь с модератором",
          link: "/cooperation/moderator"
        }
      ],
      cooperation: [
        {
          title: "Школам",
          link: "/cooperation/schools"
        },
        {
          title: "Библиотекам",
          link: "/cooperation/libraries"
        },
        {
          title: "Самозанятым",
          link: "/cooperation/selfEmployed"
        },
        {
          title: "Юридическим лицам",
          link: "/cooperation/organizations"
        },
        {
          title: "Индивидуальным предпринимателям",
          link: "/cooperation/entrepreneurs"
        }
      ],
    }
  },

  computed: {
    ...mapGetters({
      getGrades: 'gradeData/getGrades',
      sessionActive: 'authData/sessionActive'
    }),

    showAccordion() {
      return this.$route.meta.layout === 'tasks'
    },

    showSubjectClassFilter() {
      if (this.$route.name === 'landing'
        || this.$route.path === '/personal-info'
        || this.$route.path === '/profile'
        || this.$route.path === '/subscriptions'
        || this.$route.path === '/profile/subscription'
        || this.$route.path === '/settings'
        || this.$route.path === '/finance'
        || (this.$route.name === 'tasks' && this.windowWidth < 992)
        || this.$route.path.startsWith('/cooperation')
        || this.$route.path.startsWith('/books/') && this.$route.path.includes('/tasks/')
        || this.$route.path.startsWith('/books/')

        || this.$route.path === '/lessons'
        || this.$route.path === '/lessons-course'
        || this.$route.path.startsWith('/lesson')
        || this.$route.name === 'lesson'
        || this.$route.name === 'lesson-task'

        || this.$route.path === '/school'
        || this.$route.path.startsWith('/denezhki')
        || this.$route.path === '/404'
        || this.$route.path === '/favorites'
        || this.$route.path === '/gpt'
        || this.$route.path.startsWith('/gpt/')
        || this.$route.path === '/promocodes'
        || this.$route.path === '/promocode-creation'
        || this.$route.path === '/content'
        || this.$route.path.includes('/editor/resh')
        || this.$route.path === '/payment-success'
        || this.$route.path === '/payment-failure'
      ) {
          return false
      }
      return true
    },
    showSubjectClassFilterLessons() {
      if (this.$route.path === '/lessons'
        || this.$route.path === '/lessons-course'
        || this.$route.path.startsWith('/lesson')
        || this.$route.name === 'lesson'
        || this.$route.name === 'lesson-task'
      ) {
          return true
      }
      return false
    },

    showFavorites() {
      if (this.$route.name === 'favorites'
        || this.$route.path === '/personal-info'
        || this.$route.path === '/profile'
        || this.$route.path === '/subscriptions'
        || this.$route.path === '/profile/subscription'
        || this.$route.path === '/settings'
        || this.$route.path === '/finance'
        || this.$route.path === '/lessons'
        || this.$route.path === '/school'
        || this.$route.path.startsWith('/cooperation')
        || this.$route.path.startsWith('/denezhki')
        || this.$route.path.startsWith('/books')
        // || this.$route.path.startsWith('/course')
        || this.$route.path === '/404'
        || this.$route.path === '/gpt'
        || this.$route.path === '/promocodes'
        || this.$route.path === '/promocode-creation'
        || this.$route.path.startsWith('/lesson')
        || this.$route.path === '/content'
        || this.$route.path.startsWith('/gpt/')
        || this.$route.path.includes('/editor/resh')
        || this.$route.path === '/payment-success'
        || this.$route.path === '/payment-failure'
      ) {
          return false
      }
      return true
    },

    showFooter(){
      if(
        this.$route.path.startsWith('/gpt') && this.windowWidth < 568
      ){
        return false
      }
      return true
    },

    showEditorIntro() {
      // return this.$route.path.includes('/editor/resh')
    }
  },

  methods: {
    ...mapActions({
      fetchGrades: 'gradeData/fetchGrades',
      fetchCourses: 'courseData/fetchCourses',
      fetchUser: 'userData/fetchUser',
      fetchUserRoles: 'userData/fetchUserRoles',
    }),

    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    async getMenuGrades() {
      try {
        if (!this.getGrades.length) {
          await this.fetchGrades();
        }
        await this.fetchCourses();
      } catch(err) {
        console.error('Error in getMenuGrades:', err);
      }
    },

    async getUser() {
      try {
        await this.fetchUser();
      }
      catch(err) {
        console.log('Error in getUser:', err);
      }
    },

    async getRoles() {
      try {
        await this.fetchUserRoles();
      }
      catch(err) {
        console.log('Error in getRoles:', err);
      }
    },
  },

  async created() {
    await this.getMenuGrades();

    if (this.sessionActive) {
      console.warn('session is active, fetching user data...');
      await this.getUser();
      await this.getRoles();
    } else {
      console.warn('session is nonactive');
    }
    // console.log('BIG PROBLEM:', this.$getTokenInfo());
  },

  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  },
}
</script>

<style scoped>

.divide-wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1272px;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .divide-wrapper{
    max-width: 1140px;
  }
}
@media screen and (max-width: 992px) {
  .divide-wrapper{
    max-width: 720px;
  }
}
@media screen and (max-width: 768px) {
  .divide-wrapper{
    max-width: 540px;
  }
}
@media screen and (max-width: 568px) {
  .divide-wrapper{
    max-width: 100vw;
  }
}

.container__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container__aside,
.container__main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}


@media screen and (min-width: 576px) {
  /* .container__wrapper {
    max-width: 540px;  
  } */
}

@media screen and (min-width: 768px) {
  /* .container__wrapper {
    max-width: 720px;  
  } */
}

@media screen and (min-width: 992px) {
  /* .container__wrapper {
    max-width: 960px;

    flex-direction: row;
    align-items: start;
  } */

  .container__aside {
    max-width: 18%;
  }

  .container__main {
    min-width: 0;
  }
}

@media screen and (max-width: 1200px) {
  .container__wrapper {
    max-width: 1140px;  
  }
}

@media screen and (max-width: 1400px) {
  .container__wrapper {
    max-width: 1320px;  
  }
}
</style>
