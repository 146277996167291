<template>
  <section class="footer__content-section">
    <heading-size-h3 :textColor="'var(--white)'">{{ sectionTitle }}</heading-size-h3>
    <ul class="content-section__list" :class="classList">
      <li class="content-section__point" v-for="item in items">
        <a class="link" :href="item.link">
          <paragraph-size-medium :textColor="'var(--white)'">{{ item.title }}</paragraph-size-medium>
        </a>
      </li>
    </ul>
  </section>
</template>

<script>
import HeadingSizeH3 from '@/components/UI/texts/headings/HeadingSizeH3.vue';
import ParagraphSizeMedium from '@/components/UI/texts/paragraphs/ParagraphSizeMedium.vue';

export default{
  name: "footer-content-section",
  components: {
    HeadingSizeH3,
    ParagraphSizeMedium
  },
  props: {
    items: {
      type: Array,
      required: false
    },
    listClass: {
      type: String,
      default: ""
    },
    sectionTitle: {
      type: String,
      default: "Классы"
    }
  },
  computed: {
    classList() {

      return ["content__list", this.listClass].join(" ").trim();
    }
  }
};
</script>

<style scoped>
.footer__content-section{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.content-section__list{
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.content-section__list_type_row{
  flex-direction: row;
  gap: 24px;
}
.link{
  text-decoration: none;
}

@media screen and (max-width:768px) {
  .content-section__list{
    gap: 12px;
  }
  .content-section__list_type_row{
    flex-direction: column;
    gap: 16px;
  }
}
</style>