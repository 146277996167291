<template>
  <div class="header__links-wrapper">
    <nav class="header__nav">
      <ul class="header__links">
        <li>
          <router-link class="link" :to="{name: 'tasks'}">
            <paragraph-size-large
              textWeight="600"
              v-if="$route.name === 'tasks' || $route.path.startsWith('/books/') || $route.path.startsWith('/course')"
              :textColor="'var(--background-red)'"
            >
              Решения
            </paragraph-size-large>
            <paragraph-size-large
              textWeight="600"
              v-else
              :textColor="'var(--text-black)'"
              :hoverColor="'var(--background-red)'"
            >
              Решения
            </paragraph-size-large>
          </router-link>
        </li>
        <li>
          <router-link class="link" :to="{name: 'kazatel-lessons'}">
            <paragraph-size-large
              textWeight="600"
              v-if="$route.path === '/lessons'"
              :textColor="'var(--background-red)'"
            >
              Уроки
            </paragraph-size-large>
            <paragraph-size-large
              textWeight="600"
              v-else
              :textColor="'var(--text-black)'"
              :hoverColor="'var(--background-red)'"
            >
              Уроки
            </paragraph-size-large>
          </router-link>
        </li>
        <li>
          <router-link class="link" :to="{name: 'online-school'}">
            <paragraph-size-large
              textWeight="600"
              v-if="$route.path === '/school'"
              :textColor="'var(--background-red)'"
            >
              Онлайн-школа
            </paragraph-size-large>
            <paragraph-size-large
              textWeight="600"
              v-else
              :textColor="'var(--text-black)'"
              :hoverColor="'var(--background-red)'"
            >
              Онлайн-школа
            </paragraph-size-large>
          </router-link>
        </li>
      </ul>
    </nav>
    <navbar-profile/>
  </div>
</template>

<script>
import ParagraphSizeLarge from '@/components/UI/texts/paragraphs/ParagraphSizeLarge.vue';
import NavbarProfile from './NavbarProfile.vue';

export default {
  name: 'navbar-links',

  components:{
    NavbarProfile,
    ParagraphSizeLarge
  }
}
</script>

<style scoped>
.header__links-wrapper{
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: center;
}

.header__links-divider{
  display: flex;
  flex-direction: column;
}
.link{
  text-decoration: none;
}
.divider{
  height: 24px;
  width: 1px;
  border: 1px solid var(--text-light);
  border-radius: 4px;
}
.header__links{
  display: flex;
  gap: 32px;
  list-style: none;
}
@media screen and (max-width: 992px) {
  .header__links-wrapper{
    gap: 32px;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
  }

  .header__nav{
    display: flex;
    justify-content: space-between;
  }
  .header__links{
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
  }
  .divider{
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .header__links{
    flex-direction: column;
    gap: 16px;
  }
}
</style>
