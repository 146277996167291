<template>
  <div class="wrapper">
    <div class="book-info__wrapper">
      <template v-if="!overviewLoadingError">
        <book-overview
          :book="neededBook"
        >
        </book-overview>
        <!-- <book-pagination
          v-if="$route.path.startsWith('/books/') && $route.path.includes('/tasks/')"
          :currentNodeId="$route.params.tid"
          :bookId="bookId"
          :nodesList="nodesList"
          :isNodesLoading="isNodesLoading"
          @handleNodeClick="updateCurrentNode"
        /> -->
      </template>
      <card-background v-else>
        <div
          v-if="handleOverviewLoadingError(overviewLoadingError) === 'Данный материал не доступен на платформе'"
          class="error__wrapper"
        >
          <img src="@/assets/images/locked_content.png" class="error__image" />
          <div class="error__text-wrapper">
            <heading-size-h2 textColor="var(--text-black)">
              {{ handleOverviewLoadingError(overviewLoadingError) }}
            </heading-size-h2>
            <ParagraphSizeLarge textColor="var(--text-black)">
              Давай попробуем другой найти другой учебник
            </ParagraphSizeLarge>
          </div>
          <button-primary @click="backToMain">Вернуться назад</button-primary>
        </div>
      </card-background>
    </div>
    <card-background v-if="isEditor">
      <task-editor-instruction />
    </card-background>
  </div>
  <!-- WARNING: Composition API's data is MUTABLE in contrast of usual props -->
  <router-view 
    v-if="!isOverviewLoading && !isNodesLoading"
    :bookId="bookId"
    :bookInfo="neededBook"
    :nodesList="nodesList"
    :isNodesLoading="isNodesLoading"
    :nodesLoadingError="nodesLoadingError"
  />
</template>

<script>
import { defineAsyncComponent } from 'vue';
import CardBackground from '@/components/UI/card/CardBackground.vue';
import LinkHistoryTraversal from '@/components/UI/links/LinkHistoryTraversal.vue';
import ParagraphSizeLarge from '@/components/UI/texts/paragraphs/ParagraphSizeLarge.vue';
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue';
import ButtonPrimary from '@/components/UI/buttons/ButtonPrimary.vue';

import useNodesList from '@/hooks/books/useNodesList.js';
import useBookOverview from '@/hooks/books/useBookOverview.js';
import MetaTagsMixin from '@/utils/mixins/SeoInstallerMixin';
import { fetchBook } from '@/services/TaskReader';


export default {
  name: 'book',

  components: {
    LinkHistoryTraversal,
    ParagraphSizeLarge,
    HeadingSizeH2,
    BookOverview: defineAsyncComponent({
        loader: () => import('@/components/cards/BookOverview.vue'),
    }),
    BookPagination: defineAsyncComponent({
        loader: () => import('@/components/UI/BookPagination.vue'),
    }),
    CardBackground,
    TaskEditorInstruction: defineAsyncComponent({
        loader: () => import('@/components/UI/TaskEditorInstruction.vue'),
    }),
    ButtonPrimary
},

  beforeRouteEnter(to, from) {},

  setup(props, context) {
    const { nodesList, isNodesLoading, nodesLoadingError } = useNodesList();
    const { handleOverviewLoadingError } = useBookOverview();
    return { nodesList, isNodesLoading, nodesLoadingError, handleOverviewLoadingError };
  },

  mixins: [MetaTagsMixin],

  data() {
    return {
      isOverviewLoading: true,
      overviewLoadingError: null,
      nodeForTitle: '',

      neededBook:{
        id: '',
        title: '',
        level: '',
        publisher: '',
        image: '',
        authors: [],
        premium: false,
        year: '',
        type: '',
        parts: [],
        FGOS: false,
        studyDepth: ''
      }
    };
  },

  watch: { 
    '$route'() {
      this.bookId = this.$route.params.bid;
      this.fetchBook();
    },
    'currentNodeId'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updatePageTitle();
      }
    },
  },

  computed: {
    isEditor() {
      return this.$route.name === 'editor';
    }
  },

  methods: {
    async fetchBook() {
      try {
        this.isOverviewLoading = true;
        this.overviewLoadingError = null;
        const response = await fetchBook(this.bookId);
        this.book = response.toObject().book;
        if (this.book === undefined) {
          this.overviewLoadingError = { code: 7 };
        } else {
          this.neededBook.id = this.book.id;
          this.neededBook.title = this.book.name;
          this.neededBook.subject = this.book.subjectId;
          this.neededBook.level = this.book.levelsList;
          this.neededBook.publisher = this.book.publisher;
          this.neededBook.image = this.book.viewUrl;
          this.neededBook.authors = this.book.authors;
          this.neededBook.premium = this.book.attrPremium;
          this.neededBook.year = this.book.publishYear;
          this.neededBook.type = this.book.type;
          this.neededBook.parts = this.book.part;
          this.neededBook.FGOS = this.book.fgos;
          this.updatePageTitle();
        }
      } catch (err) {
        console.error('Fetch book overview:', err.message);
        this.overviewLoadingError = err;
      } finally {
        this.isOverviewLoading = false;
        this.updatePageTitle();
      }
    },
    updateCurrentNode(currentNode) {
      if (currentNode.id !== this.$route.params.tid) {
        this.nodeForTitle = currentNode.title;
        this.updatePageTitle();
      }
    },
    updatePageTitle() {
      const titleForBook = this.neededBook.title;
      const currentUrl = this.$route.path;

      if (currentUrl.includes('/tasks/')) {
        const currentNodeId = this.$route.params.tid;

        if (this.nodesList && this.nodesList[currentNodeId]) {
          const taskTitle = this.nodesList[currentNodeId].title;
          const pageTitle = titleForBook.includes('сочинения') ? taskTitle : `${titleForBook} - задание ${taskTitle}`;
          const pageDescription = titleForBook.includes('сочинения') ? `${titleForBook} - ${taskTitle}` : `Подробное решение задания ${taskTitle} по ${titleForBook}`
          this.setMetaTags(
            pageTitle,
            // `Подробное решение задания ${taskTitle} по ${titleForBook}`,
            pageDescription,
            window.location.href,
            {
              title: pageTitle,
              // description: `Подробное решение задания ${taskTitle} по ${titleForBook}`,
              description: pageDescription,
              url: window.location.href,
              image: this.bookImg,
            }
          );
        }
      } else {
        const pageTitle = `${titleForBook ? titleForBook + ' | ' : ''}Казатель Решения`;
        const pageDescription = titleForBook?.includes('сочинения') ? `${titleForBook} | Оригинальные сочинения на Казатель Решения` : `Подробное решение заданий по ${titleForBook}`
        this.setMetaTags(
          pageTitle,
          // `Подробное решение заданий по ${titleForBook}`,
          pageDescription,
          window.location.href,
          {
            title: pageTitle,
            // description: `Подробное решение заданий по ${titleForBook}`,
            description: pageDescription,
            url: window.location.href,
            image: this.bookImg,
          }
        );
      }
    },
    backToMain(){
      this.$router.push('/')
    }
  },

  async mounted() {
    await this.fetchBook();
  },

  created() {
    this.bookId = this.$route.params.bid;
    this.updatePageTitle();
  },

  beforeRouteUpdate(to, from, next) {
    this.bookId = to.params.bid;
    this.fetchBook();
    next();
  },

  beforeUpdate() {}
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.book-info__wrapper {
  display: flex;
  gap: 16px;
}
.error__wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  text-align: center;
}
.error__text-wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
@media screen and (max-width: 992px) {
  .book-info__wrapper {
    flex-direction: column;
  }

  .error__image{
    width: 100%;
  }
}
.mock-block {
  width: 100%;
  background-color: white;
  flex-basis: 1;
}
.content-wrapper {
  width: 100%;
  min-height: 50vh;
  align-self: flex-start;
}
.nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.swiper-slide {
  width: 60% !important;
}
@media screen and (min-width: 480px) {
  .swiper-slide {
    width: 40% !important;
  }
}
@media screen and (min-width: 576px) {
  .swiper-slide {
    width: 30% !important;
  }
}
@media screen and (min-width: 992px) {
  .swiper-slide {
    width: 25% !important;
  }
}
</style>
