import Book from "@/views/tasks/Book.vue";
import BookContent from "@/views/tasks/BookContent.vue";
import Tasks from "@/views/tasks/task/index.vue";
import TaskEditor from "@/views/tasks/task-editor/index.vue";

const routes = [
  {
    path: "/",
    name: "tasks",
    meta: { layout: "tasks", auth: false },
    component: () => import("@/views/tasks/Home.vue"),
  },
  {
    path: "/grade",
    name: "grade",
    meta: { layout: "tasks", auth: false },
    component: () => import("@/views/tasks/Grade.vue"),
  },
  {
    path: "/course",
    name: "course",
    meta: { layout: "tasks", auth: false },
    component: () => import("@/views/tasks/Course.vue"),
  },
  {
    path: "/books/:bid",
    name: "book",
    meta: { layout: "tasks", auth: false },
    component: Book,
    children: [
      {
        path: "",
        name: "book-content",
        meta: { layout: "tasks", auth: false },
        component: BookContent
      },
      {
        path: "tasks/:tid",
        name: "task",
        meta: { layout: "tasks", auth: false },
        component: Tasks,
      },
      {
        path: "editor/:tid",
        name: "editor",
        meta: { layout: "tasks", auth: true },
        // component: () => import(/* webpackChunkName: "group-editor" */ "@/views/tasks/task-editor/index.vue"),
        component: TaskEditor,
      }
    ]
  },
];

export default routes;
